import toast from "react-hot-toast";

export const base_url = "https://cisc-timereg-rest-test.azurewebsites.net" //TEST
export const clientId = "faf3f047-f67e-4576-a340-31f363f6aa9e" //TEST
export const scope = "https://bluedock.onmicrosoft.com/cisc-timereg-test/user_impersonation" //TEST

// export const base_url = "https://cisc-timereg-rest-prod.azurewebsites.net" //PROD
// export const clientId = "ad835422-ba43-477f-81da-b283664b6c36" //PROD
// export const scope = "https://bluedock.onmicrosoft.com/cisc-timereg/user_impersonation" //PROD

export const timeRegex = /^((?:[0-9]|[0-9][0-9]|[0-9][0-9][0-9])(?:(\.|\,|\:)(0|00|25|5|50|75?))?)$/g

export const handleError = (response) => {
    if (response) {
        console.warn(response)
        if (typeof response === "string") {
            toast.error(response)
        } else if (response?.ExceptionMessage || response?.Message) {
            toast.error(response?.ExceptionMessage || response?.Message)
        }
    }
};