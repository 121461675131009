import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { getAllFunctions, getAllServiceCategories, getAllUsers, getManagerEmployees, getMyEmployeesTimesheets, getProfileImages, getProjects, getReportTimesheets, getServiceDesiplines } from '../../services/ApiServices'
import ReactDatePicker from 'react-datepicker'
import Select from "react-select"
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import Loader from '../../components/Loader/Loader'
import { handleError } from '../../const'
import './Dashboard.scss'

import downArrow from "./../../assets/img/icon-chevron-down.svg"
import profile from "./../../assets/img/img-placeholder.png"
import { UserImage } from '../../components/UserImage/UserImage'

const Dashboard = () => {
    const currentUser = useSelector((state) => state.MSALAuth.currentUser)
    const { state } = useLocation()

    const [isLoading, setIsLoading] = useState(false)
    const [isPromiseLoading, setIsPromiseLoading] = useState(false)

    // const [calendarDate, setCalendarDate] = useState(moment().format("YYYY-MM-DD"))
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"))

    const [allProjects, setAllProjects] = useState([])
    const [allServiceCategories, setAllServiceCategories] = useState([])
    const [allFunctions, setAllFunctions] = useState([])
    const [allServiceDisciplines, setAllServiceDisciplines] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const [allManagerEmployees, setAllManagerEmployees] = useState([])
    const [myEmployeesTimesheets, setMyEmployeesTimesheets] = useState([])
    const [myTimesheets, setMyTimesheets] = useState([])
    const [myEmpAgrregatedTimesheet, setMyEmpAgrregatedTimesheet] = useState([])
    const [userSelectOptions, setUserSelectOptions] = useState([])

    const [functionId, setFunctionId] = useState(0)
    const [serviceCategoryId, setServiceCategoryId] = useState(0)
    const [serviceDisplineId, setServiceDisplineId] = useState(0)
    const [projectId, setProjectId] = useState(0)

    const [queryStringUserFilter, setQueryStringUserFilter] = useState("")
    const [userFilter, setUserFilter] = useState([])

    const [isMyEmployeeActive, setIsMyEmployeeActive] = useState(false)
    const [isMyTimesheetsActive, setIsMyTimesheetsActive] = useState(false)
    const [showAggregated, setShowAggregated] = useState(false)
    const [accordionId, setAccordionId] = useState(null) // null || number

    // filter MyEmployeesTimesheets
    const filteredMyEmployeesTimesheets = useMemo(() => {

        let userFiltered = []
        if (userFilter.length > 0) {
            const userUPNs = userFilter.map((user) => user?.UPN)
            const filteredTimesheets = myEmployeesTimesheets.filter((employee) => userUPNs.includes(employee?.user))

            userFiltered = filteredTimesheets
        } else {
            userFiltered = myEmployeesTimesheets
        }

        let arr = userFiltered.map((emp) => ({
            ...emp,
            TimeSheetReportData: emp?.TimeSheetReportData.filter((d) => {
                if (functionId || serviceCategoryId || serviceDisplineId || projectId) {
                    return (
                        (functionId ? d?.FunctionId === functionId : true) &&
                        (serviceCategoryId ? d?.ServiceCategoriesId === serviceCategoryId : true) &&
                        (serviceDisplineId ? d?.ServiceDisciplineId === serviceDisplineId : true) &&
                        (projectId ? d?.ProjectId === projectId : true)
                    )
                } else {
                    return true
                }
            })
        }))

        return arr?.filter((d) => d?.TimeSheetReportData && d?.TimeSheetReportData?.length > 0)
    }, [myEmployeesTimesheets, functionId, serviceCategoryId, serviceDisplineId, projectId, userFilter])

    // filter MyEmpAgrregatedTimesheet
    const filteredMyEmpAgrregatedTimesheet = useMemo(() => {

        let userFiltered = []
        if (userFilter.length > 0) {
            const userUPNs = userFilter.map((user) => user?.UPN)
            const filteredTimesheets = myEmpAgrregatedTimesheet.filter((employee) => userUPNs.includes(employee?.UserUPN))
            userFiltered = filteredTimesheets
        } else {
            userFiltered = myEmpAgrregatedTimesheet
        }

        let arr = userFiltered.filter((d) => {
            if (functionId || serviceCategoryId || serviceDisplineId || projectId) {
                return (
                    (functionId ? d?.FunctionId === functionId : true) &&
                    (serviceCategoryId ? d?.ServiceCategoriesId === serviceCategoryId : true) &&
                    (serviceDisplineId ? d?.ServiceDisciplineId === serviceDisplineId : true) &&
                    (projectId ? d?.ProjectId === projectId : true)
                )
            } else {
                return true
            }
        })

        return arr
    }, [myEmpAgrregatedTimesheet, functionId, serviceCategoryId, serviceDisplineId, projectId, userFilter])

    // filter MyTimesheets
    const filteredmyTimesheets = useMemo(() => {
        let arr = myTimesheets.filter((d) => {
            if (functionId || serviceCategoryId || serviceDisplineId || projectId) {
                return (
                    (functionId ? d?.FunctionId === functionId : true) &&
                    (serviceCategoryId ? d?.ServiceCategoriesId === serviceCategoryId : true) &&
                    (serviceDisplineId ? d?.ServiceDisciplineId === serviceDisplineId : true) &&
                    (projectId ? d?.ProjectId === projectId : true)
                )
            } else {
                return true
            }
        })

        return arr
    }, [myTimesheets, functionId, serviceCategoryId, serviceDisplineId, projectId])

    const CustomStartDatePicker = forwardRef(({ value, onClick }, ref) =>
        <div className="">
            <button type="button" onClick={onClick} ref={ref} className="btn btn-primary-w btn-icon2 cal-btn">
                {/* <i className="fa-regular fa-calendar" /> */}
                {moment(startDate).format("DD MMM")}
                {", "}
                {moment(startDate).year()}
            </button>
        </div>
    )

    const CustomEndDatePicker = forwardRef(({ value, onClick }, ref) =>
        <div className="">
            <button type="button" onClick={onClick} ref={ref} className="btn btn-primary-w btn-icon2 cal-btn">
                {/* <i className="fa-regular fa-calendar" /> */}
                {moment(endDate).format("DD MMM")}
                {", "}
                {moment(endDate).year()}
            </button>
        </div>
    )

    // Clear Filter
    const handleClearFilter = () => {
        setFunctionId(0)
        setServiceCategoryId(0)
        setServiceDisplineId(0)
        setProjectId(0)
    }

    // Remove Duplicate User
    const removeDupeUser = (allTimesheets) => {
        const uniqueUsers = []
        const usersSet = new Set()

        allTimesheets?.length > 0 && allTimesheets.forEach((item) => {
            if (!usersSet.has(item?.user)) {
                usersSet.add(item?.user)
                uniqueUsers.push(item?.user)
            }
        });

        return uniqueUsers;
    }

    // Percentage from total
    const calculatePercentage = (currentTime, totalTime) => {
        if (currentTime === 0 || totalTime === 0 || totalTime === "0.00") {
            return 0
        } else {
            return (currentTime / totalTime) * 100;
        }
    };

    // Week Total Time
    const allProjectTotalTime = (data) => {
        return data?.reduce((total, entry) => {
            const hours = parseFloat(entry.TotalHour) || 0
            return total + hours
        }, 0);
    }

    useEffect(() => {
        if (currentUser?.Data?.IsManager && isMyEmployeeActive) {
            setIsLoading(true)
            setFunctionId(0)
            setServiceCategoryId(0)
            setServiceDisplineId(0)
            setProjectId(0)

            getMyEmployeesTimesheets(startDate, endDate).then((response) => {
                if (response?.Success) {
                    setMyEmployeesTimesheets(response?.Data.length > 0 ? response?.Data : [])
                }
            }).catch((error) => {
                console.log('error', error)
            }).finally(() => {
                setIsLoading(false)
            })
        }

        if (isMyTimesheetsActive) {
            setIsLoading(true)
            setFunctionId(0)
            setServiceCategoryId(0)
            setServiceDisplineId(0)
            setProjectId(0)

            getReportTimesheets(startDate, endDate).then((response) => {
                if (response?.Success) {
                    setMyTimesheets(response?.Data)
                }
            }).catch((error) => {
                console.log('error', error)
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }, [isMyEmployeeActive, isMyTimesheetsActive, startDate, endDate, currentUser?.Data?.IsManager])

    // Timesheets
    useEffect(() => {
        let tempMyEmpAgg = []
        myEmployeesTimesheets.map((user) =>
            user?.TimeSheetReportData.map((timesheet) =>
                tempMyEmpAgg = [...tempMyEmpAgg, timesheet]
            )
        )
        setMyEmpAgrregatedTimesheet(tempMyEmpAgg)
    }, [myEmployeesTimesheets])

    // Get Project, Categories, Disiplines, Functions, Users, ManagerEmployees
    useEffect(() => {
        let subscribed = true;

        setIsPromiseLoading(true)
        setAllProjects([])
        setAllServiceCategories([])
        setAllServiceDisciplines([])
        setAllFunctions([])
        setAllUsers([])
        setAllManagerEmployees([])

        Promise.all([getProjects(), getAllServiceCategories(), getServiceDesiplines(), getAllFunctions(), getAllUsers(), getManagerEmployees()]).then((response) => {
            if (subscribed) {
                const projectResponse = response[0]
                const serviceCategoryResponse = response[1]
                const serviceDisiplineResponse = response[2]
                const teamsResponse = response[3]
                const userResponse = response[4]
                const managerEmployeesResponse = response[5]

                if (typeof projectResponse?.Data === "object" && projectResponse?.Data?.length >= 0) {
                    setAllProjects(projectResponse?.Data)
                } else {
                    handleError(projectResponse)
                }

                if (typeof serviceCategoryResponse?.Data === "object" && serviceCategoryResponse?.Data?.length >= 0) {
                    setAllServiceCategories(serviceCategoryResponse?.Data)
                } else {
                    handleError(serviceCategoryResponse)
                }

                if (typeof serviceDisiplineResponse?.Data === "object" && serviceDisiplineResponse?.Data?.length >= 0) {
                    setAllServiceDisciplines(serviceDisiplineResponse?.Data)
                } else {
                    handleError(serviceDisiplineResponse)
                }

                if (typeof teamsResponse?.Data === "object" && teamsResponse?.Data?.length >= 0) {
                    setAllFunctions(teamsResponse?.Data)
                } else {
                    handleError(teamsResponse)
                }

                if (typeof userResponse?.Data === "object" && userResponse?.Data?.length >= 0) {
                    setAllUsers(userResponse?.Data)
                } else {
                    handleError(userResponse)
                }

                if (typeof managerEmployeesResponse?.Data === "object" && managerEmployeesResponse?.Data?.length >= 0) {
                    setAllManagerEmployees(managerEmployeesResponse?.Data)
                } else {
                    handleError(managerEmployeesResponse)
                }
            }
        }).catch((error) => {
            if (subscribed) {
                handleError(error)
            }
        }).finally(() => {
            if (subscribed) {
                setIsPromiseLoading(false);
            }
        })
    }, [])

    useEffect(() => {
        if (currentUser?.Data?.IsAdmin) {
            setUserSelectOptions(allUsers)
        } else if (currentUser?.Data?.IsManager) {
            setUserSelectOptions(allManagerEmployees)
        } else {
            setUserSelectOptions(currentUser?.Data?.PersonalAssistantManager)
        }
    }, [allUsers, allManagerEmployees, currentUser])

    useEffect(() => {
        if (state?.key) {
            if (currentUser?.Data?.IsManager) {
                setIsMyEmployeeActive(true)
                setIsMyTimesheetsActive(false)
            } else {
                setIsMyEmployeeActive(false)
                setIsMyTimesheetsActive(true)
            }
        } else {
            setIsMyEmployeeActive(false)
            setIsMyTimesheetsActive(true)
        }
    }, [])

    return (
        <>
            {isLoading && <Loader />}
            {isPromiseLoading && <Loader />}

            <main id='main' className='dashboard-main'>
                <div className='pagetitle mb-4'>
                    <h1>Report</h1>
                </div>
                <section className='section'>
                    {/* Calendar Header */}
                    <div className="calendar-header mb-4 d-flex justify-content-between">
                        <div className="d-flex justify-content-between align-items-center">
                            {/* Start date */}
                            <div className='d-flex justify-content-center align-items-center'>
                                <span className='me-2'>Start date : </span>
                                <div className="calendar input-group d-flex align-items-center me-3">
                                    <ReactDatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={startDate}
                                        onChange={(date) => date && setStartDate(moment(date).format("YYYY-MM-DD"))}
                                        customInput={<CustomStartDatePicker />}
                                        className='form-control'
                                        placeholderText='Select start date'
                                    />
                                </div>
                            </div>

                            {/* End date */}
                            <div className='d-flex justify-content-center align-items-center'>
                                <span className='me-2'>End date : </span>
                                <div className="calendar input-group d-flex align-items-center me-3">
                                    <ReactDatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={endDate}
                                        onChange={(date) => date && setEndDate(moment(date).format("YYYY-MM-DD"))}
                                        customInput={<CustomEndDatePicker />}
                                        className='form-control'
                                        placeholderText='Select end date'
                                    />
                                </div>
                            </div>

                            {/* My-Employees */}
                            {currentUser?.Data?.IsManager &&
                                <>
                                    <div className="my-employees me-1">
                                        <button
                                            type='button'
                                            className={`btn px-4 py-2 ${isMyEmployeeActive ? "active" : ""}`}
                                            onClick={() => {
                                                setIsMyEmployeeActive(true)
                                                setIsMyTimesheetsActive(false)
                                                setShowAggregated(false)
                                            }}
                                        >
                                            My Employees
                                        </button>
                                    </div>

                                    <div className="my-timesheets me-3">
                                        <button
                                            type='button'
                                            className={`btn px-4 py-2 ${isMyTimesheetsActive ? "active" : ""}`}
                                            onClick={() => {
                                                setIsMyTimesheetsActive(true)
                                                setIsMyEmployeeActive(false)
                                                setUserFilter([])
                                            }}
                                        >
                                            My Timesheets
                                        </button>
                                    </div>
                                </>
                            }

                            {currentUser?.Data?.IsManager && isMyEmployeeActive &&
                                <div className="form-check form-switch d-inline-flex gap-5">
                                    <span className="textgreen" htmlFor="#showAggregated">Show Aggregated</span>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="showAggregated"
                                        checked={showAggregated}
                                        onChange={() => setShowAggregated((prev) => !prev)}
                                    />
                                </div>
                            }
                        </div>
                    </div>

                    <div className='drop-down-section col-lg-8 col-md-12 col-sm-12 d-flex gap-2 mb-4'>
                        <div className="col-2 function-select">
                            <span className='fw-medium'>Function</span>
                            <Select
                                value={functionId === 0
                                    ? { value: 0, label: "Select" }
                                    : { value: functionId, label: allFunctions?.find((func) => func?.Id === functionId)?.Name }
                                }
                                onChange={(e) => setFunctionId(Number(e.value))}
                                options={allFunctions.map((func) => (
                                    ({ value: func?.Id, label: func?.Name })
                                ))}
                                className="form-control p-0 my-2"
                                placeholder="Select"
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        marginTop: 2,
                                        border: "none",
                                        boxShadow: "none",
                                    }),
                                }}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </div>
                        <div className="col-2 cate-select">
                            <span className='fw-medium'>Service Category</span>
                            <Select
                                value={serviceCategoryId === 0
                                    ? { value: 0, label: "Select" }
                                    : { value: serviceCategoryId, label: allServiceCategories?.find((cate) => cate?.Id === serviceCategoryId)?.ServiceCategoriesName }
                                }
                                onChange={(e) => setServiceCategoryId(Number(e.value))}
                                options={allServiceCategories.map((category) =>
                                    ({ value: category?.Id, label: category?.ServiceCategoriesName })
                                )}
                                className="form-control p-0 my-2"
                                placeholder="Select"
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        marginTop: 2,
                                        border: "none",
                                        boxShadow: "none",
                                    }),
                                }}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </div>
                        <div className="col-2 disci-select">
                            <span className='fw-medium'>Service Discipline</span>
                            <Select
                                value={serviceDisplineId === 0
                                    ? { value: 0, label: "Select" }
                                    : { value: serviceDisplineId, label: allServiceDisciplines?.find((disc) => disc?.Id === serviceDisplineId)?.ServiceDisciplinesName }
                                }
                                onChange={(e) => setServiceDisplineId(Number(e.value))}
                                options={allServiceDisciplines.map((disci) =>
                                    ({ value: disci?.Id, label: disci?.ServiceDisciplinesName })
                                )}
                                className="form-control p-0 my-2"
                                placeholder="Select"
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        marginTop: 2,
                                        border: "none",
                                        boxShadow: "none",
                                    }),
                                }}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </div>
                        <div className="col-2 project-select">
                            <span className='fw-medium'>Project</span>
                            <Select
                                value={projectId === 0
                                    ? { value: 0, label: "Select" }
                                    : { value: projectId, label: allProjects?.find((proj) => proj?.Id === projectId)?.ProjectName }
                                }
                                onChange={(e) => setProjectId(Number(e.value))}
                                options={allProjects.map((pro) =>
                                    ({ value: pro?.Id, label: pro?.ProjectName })
                                )}
                                className="form-control p-0 my-2"
                                placeholder="Select"
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        marginTop: 2,
                                        border: "none",
                                        boxShadow: "none",
                                    }),
                                }}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </div>
                        <div className="col-3 manager-employees">
                            <span className='fw-medium'>User select</span>
                            <Select
                                className="form-control p-0 my-2"
                                value={userFilter && userFilter?.length > 0 ? (
                                    userFilter?.map((user) => ({
                                        label: userSelectOptions?.find((me) => me?.UPN === user?.UPN)?.Name,
                                        value: userSelectOptions?.find((me) => me?.UPN === user?.UPN)?.UPN
                                    }))
                                ) : null}
                                isMulti
                                inputValue={queryStringUserFilter}
                                onInputChange={(e) => setQueryStringUserFilter(e)}
                                onChange={(e) => {
                                    setUserFilter(e?.map((d) => userSelectOptions?.find((user) => user?.UPN === d?.value) && ({
                                        Name: d?.label,
                                        UPN: d?.value
                                    })))
                                }}
                                options={userSelectOptions.length > 0
                                    ? [...userSelectOptions?.map((manager) => ({
                                        label: manager?.Name,
                                        value: manager?.UPN,
                                    }))]
                                    : []
                                }
                                components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator: () => null,
                                }}
                            />
                        </div>
                        <div className="col-2 clear-select d-flex align-items-end">
                            <button className='btn fw-medium form-control d-flex align-items-center p-3 mb-2' onClick={() => handleClearFilter()}>
                                Clear selection
                            </button>
                        </div>
                    </div>

                    {currentUser?.Data?.IsManager && isMyEmployeeActive
                        ? showAggregated
                            ? (
                                // Aggregated View
                                <table className='table tableCustom table-hover'>
                                    <thead className='w-100'>
                                        <tr className='row'>
                                            <th className='col-6 table-header-title fw-normal py-2' scope='col'>Registrations</th>
                                            <th className='col-6 text-end py-2' scope='col'></th>
                                        </tr>
                                    </thead>
                                    <tbody className='w-100'>
                                        {filteredMyEmpAgrregatedTimesheet.length > 0 && filteredMyEmpAgrregatedTimesheet.length > 0 ?
                                            (filteredMyEmpAgrregatedTimesheet.map((seq, seqIndex) =>
                                                <tr key={seqIndex} className='row'>
                                                    <td className='project-details col-6 txt-medium py-2'>
                                                        <div className="fw-medium project-display">
                                                            {allServiceCategories && allServiceCategories?.length > 0
                                                                ? allServiceCategories?.find((cate) => cate?.Id === seq?.ServiceCategoriesId)?.ServiceCategoriesName
                                                                ?? "--"
                                                                : "--"
                                                            }
                                                            {" - "}
                                                            {allServiceDisciplines && allServiceDisciplines?.length > 0
                                                                ? allServiceDisciplines?.find((disc) => disc?.Id === seq?.ServiceDisciplineId)?.ServiceDisciplinesName
                                                                ?? "--"
                                                                : "--"
                                                            }
                                                        </div>
                                                        <div className="text-black-50 comments-display">
                                                            {allProjects && allProjects?.length > 0
                                                                ? allProjects?.find((pro) => pro?.Id === seq?.ProjectId)?.ProjectName
                                                                ?? "--"
                                                                : "--"
                                                            }
                                                            {" - "}
                                                            {allFunctions && allFunctions?.length > 0
                                                                ? allFunctions?.find((func) => func?.Id === seq?.FunctionId)?.Name
                                                                ?? "--"
                                                                : "--"
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className='col-6 buttons fw-medium fs-6 py-2 d-flex justify-content-end align-items-center' align='right'>
                                                        {seq?.TotalHour ?? "00"} Hrs. | {calculatePercentage(seq?.TotalHour ?? "00", allProjectTotalTime(filteredMyEmpAgrregatedTimesheet).toFixed(2)).toFixed(2) ?? "00"} %
                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr className="seq-row">
                                                    <td className='col-12 text-center'>
                                                        No Data
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        <tr className='total-month-hours row text-end fs-4'>
                                            <div className='fs-3 fw-normal'>
                                                {allProjectTotalTime(filteredMyEmpAgrregatedTimesheet).toFixed(2) === "0.00" ? "" : allProjectTotalTime(filteredMyEmpAgrregatedTimesheet).toFixed(2)}
                                            </div>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                            : (
                                // Accordian View
                                <table className="userTable table tableCustom table-hover valignM tableUsers tableHours">
                                    {filteredMyEmployeesTimesheets && filteredMyEmployeesTimesheets.length > 0 ?
                                        (filteredMyEmployeesTimesheets.map((emp, empIndex) =>
                                            <tbody key={empIndex} className='w-100'>
                                                {emp?.TimeSheetReportData.length > 0 && (
                                                    <>
                                                        <tr className="row userHeader">
                                                            <td className="col-6 header-arrow-btn d-flex py-3">
                                                                <button
                                                                    className={`btn btn-primary-w btn-icon-gray d-flex justify-content-center align-items-center p-0 me-2 shadow0 btnToggle${accordionId === empIndex ? " btn-border" : ""}`}
                                                                    type="text"
                                                                    onClick={() => setAccordionId((prev) => prev !== empIndex ? empIndex : null)}
                                                                >
                                                                    <img src={downArrow} alt="down-arrow" />
                                                                </button>
                                                                <div className="nav-profile d-flex align-items-center w-100 pe-0" onClick={() => setAccordionId((prev) => prev !== empIndex ? empIndex : null)}>
                                                                    <UserImage className="rounded-circle profile-width me-3" email={emp?.user} size={40} border="none" />
                                                                    <span className='fw-medium'>
                                                                        {allUsers && allUsers?.length > 0 && allUsers?.find((u) => u?.Email === emp?.user)?.Name}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td className="col-6 d-flex justify-content-end align-items-center fw-medium pe-3" onClick={() => setAccordionId((prev) => prev !== empIndex ? empIndex : null)}>
                                                                <span>{allProjectTotalTime(emp?.TimeSheetReportData).toFixed(2)} Hrs.</span>
                                                            </td>
                                                        </tr>
                                                        <tr className={`row listing table-row${accordionId === empIndex ? "" : " d-none"}`}>
                                                            <td className="p-0">
                                                                <div className="divApproval">
                                                                    <table className="table tableCustom valignM tableHours mb-0">
                                                                        <thead className="table-date-header w-100">
                                                                            <tr className="header-row">
                                                                                <th scope="col" className='col-6 table-header-title fw-normal text-start'>Registrations</th>
                                                                                <th scope="col" className='col-6'>&nbsp;</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="table-date-body w-100">
                                                                            {emp?.TimeSheetReportData && emp?.TimeSheetReportData.length > 0 ? (
                                                                                emp?.TimeSheetReportData.map((seq, seqIndex) =>
                                                                                    <tr key={seqIndex} className="seq-row">
                                                                                        <td className="body-project-col col-6">
                                                                                            <div className="project-div d-flex align-items-center text-start">
                                                                                                <div className="">
                                                                                                    <div className="project-display fw-medium p-0">
                                                                                                        {allServiceCategories && allServiceCategories?.length > 0
                                                                                                            ? allServiceCategories?.find((cate) => cate?.Id === seq?.ServiceCategoriesId)?.ServiceCategoriesName
                                                                                                            ?? "--"
                                                                                                            : "--"
                                                                                                        }
                                                                                                        {" - "}
                                                                                                        {allServiceDisciplines && allServiceDisciplines?.length > 0
                                                                                                            ? allServiceDisciplines?.find((disc) => disc?.Id === seq?.ServiceDisciplineId)?.ServiceDisciplinesName
                                                                                                            ?? "--"
                                                                                                            : "--"
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="comments-display text-black-50 p-0">
                                                                                                        {allProjects && allProjects?.length > 0
                                                                                                            ? allProjects?.find((pro) => pro?.Id === seq?.ProjectId)?.ProjectName
                                                                                                            ?? "--"
                                                                                                            : "--"
                                                                                                        }
                                                                                                        {" - "}
                                                                                                        {allFunctions && allFunctions?.length > 0
                                                                                                            ? allFunctions?.find((func) => func?.Id === seq?.FunctionId)?.Name
                                                                                                            ?? "--"
                                                                                                            : "--"
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className='col-6 text-end'>
                                                                                            <span>
                                                                                                {seq?.TotalHour ?? "00"} Hrs. | {calculatePercentage(seq?.TotalHour ?? "00", allProjectTotalTime(emp?.TimeSheetReportData).toFixed(2)).toFixed(2) ?? "00"} %
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            ) : (
                                                                                <tr className="seq-row">
                                                                                    <td className='col-12 text-center'>
                                                                                        No Data
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        )) : (
                                            <tr className="seq-row">
                                                <td className='col-12 text-center'>
                                                    No Data
                                                </td>
                                            </tr>
                                        )
                                    }
                                </table>
                            )
                        : (
                            // My Timesheets
                            <table className='table tableCustom table-hover'>
                                <thead className='w-100'>
                                    <tr className='row'>
                                        <th className='col-6 table-header-title fw-normal py-2' scope='col'>Registrations</th>
                                        <th className='col-6 text-end py-2' scope='col'></th>
                                    </tr>
                                </thead>
                                <tbody className='w-100'>
                                    {filteredmyTimesheets && filteredmyTimesheets.length > 0 ?
                                        (filteredmyTimesheets.map((seq, seqIndex) =>
                                            <tr key={seqIndex} className='row'>
                                                <td className='project-details col-6 txt-medium py-2'>
                                                    <div className="fw-medium project-display">
                                                        {allServiceCategories && allServiceCategories?.length > 0
                                                            ? allServiceCategories?.find((cate) => cate?.Id === seq?.ServiceCategoriesId)?.ServiceCategoriesName
                                                            ?? "--"
                                                            : "--"
                                                        }
                                                        {" - "}
                                                        {allServiceDisciplines && allServiceDisciplines?.length > 0
                                                            ? allServiceDisciplines?.find((disc) => disc?.Id === seq?.ServiceDisciplineId)?.ServiceDisciplinesName
                                                            ?? "--"
                                                            : "--"
                                                        }
                                                    </div>
                                                    <div className="text-black-50 comments-display">
                                                        {allProjects && allProjects?.length > 0
                                                            ? allProjects?.find((pro) => pro?.Id === seq?.ProjectId)?.ProjectName
                                                            ?? "--"
                                                            : "--"
                                                        }
                                                        {" - "}
                                                        {allFunctions && allFunctions?.length > 0
                                                            ? allFunctions?.find((func) => func?.Id === seq?.FunctionId)?.Name
                                                            ?? "--"
                                                            : "--"
                                                        }
                                                    </div>
                                                </td>
                                                <td className='col-6 buttons fw-medium fs-6 py-2 d-flex justify-content-end align-items-center' align='right'>
                                                    {seq?.TotalHour ?? "00"} Hrs. | {calculatePercentage(seq?.TotalHour ?? "00", allProjectTotalTime(filteredmyTimesheets).toFixed(2)).toFixed(2) ?? "00"} %
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr className="seq-row">
                                                <td className='col-12 text-center'>
                                                    No Data
                                                </td>
                                            </tr>
                                        )
                                    }
                                    <tr className='total-month-hours row text-end fs-4'>
                                        <div className='fs-3 fw-normal'>
                                            {allProjectTotalTime(filteredmyTimesheets).toFixed(2) === "0.00" ? "" : allProjectTotalTime(filteredmyTimesheets).toFixed(2)}
                                        </div>
                                    </tr>
                                </tbody>
                            </table>
                        )
                    }
                </section>
            </main>
        </>
    )
}

export default Dashboard