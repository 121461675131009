/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef, useEffect, useMemo, useState } from "react"
import { insertUpdateTimesheets, createUpdateTimesheet, deleteTimeSheet, getAllFunctions, getAllServiceCategories, getAllTimeSheets, getProjects, getServiceDesiplines, getUserFavourite, submitTimesheet, getHolidayByCountryAndYear } from "../../services/ApiServices"
import Loader from "../../components/Loader/Loader"
import ReactDatePicker from "react-datepicker"
import { handleError } from "../../const"
import { useSelector } from "react-redux"
import { Tooltip } from 'react-tooltip'
import toast from "react-hot-toast"
import Select from "react-select"
import swal from "sweetalert"
import moment from "moment"
import $ from "jquery"
import "./Timesheet.scss"

const Timesheet = () => {
    const currentUser = useSelector((state) => state.MSALAuth.currentUser)

    const [isLoading, setIsLoading] = useState(false)
    const [isPromiseLoading, setIsPromiseLoading] = useState(false)
    const [isAutoSaveing, setIsAutoSaveing] = useState(false)

    const [allTimesheetsResponse, setAllTimesheetsResponse] = useState([])
    const [allTimesheets, setAllTimesheets] = useState([])
    const [allProjects, setAllProjects] = useState([])
    const [allServiceCategories, setAllServiceCategories] = useState([])
    const [allFunctions, setAllFunctions] = useState([])
    const [allServiceDisciplines, setAllServiceDisciplines] = useState([])
    const [allFourites, setAllFourites] = useState([])
    const [allHolidays, setAllHolidays] = useState([])

    const [sequenceData, setSequenceData] = useState([])
    const [submitedMonthArray, setSubmitedMonthArray] = useState([])
    const [defaultHolidaySelection, setDefaultHolidaySelection] = useState({ FunctionId: 0, ServiceCategoriesId: 0, ServiceDisciplinesId: 0 })
    const [inputFunction, setInputFunction] = useState(0)
    const [inputServiceCategory, setInputServiceCategory] = useState(0)
    const [inputServiceDiscipline, setInputServiceDiscipline] = useState(0)
    const [inputProject, setInputProject] = useState(0)
    const [inputComment, setInputComment] = useState("")
    const [inputSequence, setInputSequence] = useState(0)
    const [inputIsMobile, setInputIsMobile] = useState(false)
    const [inputNotes, setInputNotes] = useState("")
    const [inputSeqData, setInputSeqData] = useState({})

    const [userUpnPa, setUserUpnPa] = useState(currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UPN === currentUser?.Data?.UPN)?.UserId)
    const [calendarDate, setCalendarDate] = useState(moment().format("YYYY-MM-DD"))
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 480);
    const [currentWeek, setCurrentWeek] = useState([])

    const [disableCondition1, setDisableCondition1] = useState(false)
    const [disableCondition2, setDisableCondition2] = useState(false)
    const [disableCondition3, setDisableCondition3] = useState(false)
    const [lockPeriodDisableCondition, setLockPeriodDisableCondition] = useState(false)

    // Copy Modal
    const [sequenceCheckData, setSequenceCheckData] = useState([])
    const [checkArray, setCheckArray] = useState([])
    const [checkDate, setCheckDate] = useState(moment().format("YYYY-MM-DD"))
    const [checkAllTimesheets, setCheckAllTimesheets] = useState([])
    const [isCheckSubmited, setIsCheckSubmited] = useState(false)
    const [isCopyModalOpen, setIsCopyModalOpen] = useState(false)

    const weekDates = useMemo(() => {
        let dates = []

        const weekStart = isMobileView
            ? moment(calendarDate).format("YYYY-MM-DD")
            : moment(calendarDate).startOf("isoWeek").format("YYYY-MM-DD")
        Array.from(Array(isMobileView ? 1 : 7), (_, i) => i + 1)?.forEach((_, i) => {
            dates.push(moment(weekStart).add(i, "d").format("YYYY-MM-DD"))
        })

        return dates
    }, [calendarDate, isMobileView])

    const start = weekDates[0]
    const end = weekDates[weekDates?.length - 1]
    const userEmail = currentUser?.Data?.PersonalAssistantManager?.length > 0 && userUpnPa && currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN

    // Remove Duplicate Sequence
    const removeDupe = (timesheet) => {
        let unique = [];

        if (timesheet?.length > 0) {
            timesheet?.forEach((e) => {
                if (!unique.includes(e?.Sequence)) {
                    unique.push(e?.Sequence);
                }
            });
        } else {
            if (allFourites?.length > 0) {
                allFourites?.forEach((e, index) => {
                    unique.push(index + 1);
                });
            } else {
                unique.push(1)
            }
        }
        return unique;
    }

    const checkIsSubmitedMonthWise = (sequenceDateData) => {
        const tempIsSubmitedMonthWise = sequenceDateData?.map((seq) => {
            const months = {};
            // Group seqArray items by month
            seq?.seqArray?.forEach((week) => {
                const month = moment(week?.Date, "YYYY-MM-DD").format("YYYY-MM");
                if (!months[month]) {
                    months[month] = [];
                }
                months[month].push(week);
            });

            // Check if any month has IsSubmited set to true
            const hasSubmittedMonth = Object.values(months)?.some((monthArray) =>
                monthArray?.some((item) => item?.IsSubmited)
            );

            // Update IsSubmited for all items in months with submitted items
            const updatedSeqArray = seq?.seqArray?.map((week) => {
                const month = moment(week?.Date, "YYYY-MM-DD").format("YYYY-MM");
                if (hasSubmittedMonth && months[month]?.some((item) => item?.IsSubmited)) {
                    return {
                        ...week,
                        IsSubmited: true
                    };
                }
                return week;
            });

            return {
                ...seq,
                seqArray: updatedSeqArray
            };
        });

        const submitedMonth = tempIsSubmitedMonthWise?.map((seq) => ({
            ...seq,
            seqArray: seq?.seqArray?.map((data) => ({
                ...data,
                IsSubmited: submitedMonthArray?.length > 0
                    ? submitedMonthArray?.some((month) => moment(month, "MMMM").format("MM") === moment(data?.Date, "YYYY-MM-DD").format("MM"))
                    : false
            }))
        }))
        return submitedMonth
    }

    // Sequence Sorting
    useEffect(() => {
        let sequenceDateData = []
        removeDupe(allTimesheets)?.forEach((d, index) => {
            const currentSequenceTotalArray = allTimesheets?.filter((timeSheet) => timeSheet?.Sequence === d)
            const isMobileTrueArray = currentSequenceTotalArray?.filter((timeSheet) => timeSheet?.IsMobile)
            const isMobileFalseArray = currentSequenceTotalArray?.filter((timeSheet) => !timeSheet?.IsMobile)

            if (allTimesheets.length > 0) {
                if (isMobileTrueArray?.length > 0) {
                    sequenceDateData.push({
                        Sequence: d,
                        IsMobile: true,
                        seqArray: weekDates?.map((date, dateIndex) => {
                            const defaultData = allTimesheets?.filter((timeSheet) => timeSheet?.IsMobile)?.find((timesheet) => timesheet?.Sequence === d)
                            const data = allTimesheets?.filter((timeSheet) => timeSheet?.IsMobile)?.find((timesheet) =>
                                timesheet?.Sequence === d &&
                                timesheet?.Date === date
                            )

                            return data ? (
                                data
                            ) : allTimesheets?.filter((timeSheet) => timeSheet?.IsMobile).length > 0 ? {
                                ...defaultData,
                                Id: 0,
                                Hour: "",
                                Date: date,
                                IndexId: dateIndex,
                                Notes: "",
                            } : allFourites[index]
                                ? ({
                                    Comment: "",
                                    Date: date,
                                    FunctionId: allFourites[index]?.FunctionId,
                                    Hour: "",
                                    IsMobile: false,
                                    Hours: "00:00:00",
                                    Id: 0,
                                    IndexId: dateIndex,
                                    IsSubmited: false,
                                    ProjectId: allFourites[index]?.ProjectId,
                                    Sequence: d,
                                    ServiceCategoriesId: allFourites[index]?.ServiceCategoriesId,
                                    ServiceDisciplinesId: allFourites[index]?.ServiceDisciplinesId,
                                    User: currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN,
                                    Notes: ""
                                }) : ({
                                    Comment: "",
                                    Date: date,
                                    FunctionId: 0,
                                    Hour: "",
                                    Hours: "00:00:00",
                                    IsMobile: false,
                                    Id: 0,
                                    IndexId: dateIndex,
                                    IsSubmited: false,
                                    ProjectId: 0,
                                    Sequence: 1,
                                    ServiceCategoriesId: 0,
                                    ServiceDisciplinesId: 0,
                                    User: currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN,
                                    Notes: ""
                                })
                        })
                    })
                }

                if (isMobileFalseArray?.length > 0) {
                    sequenceDateData.push({
                        Sequence: d,
                        IsMobile: false,
                        seqArray: weekDates?.map((date, dateIndex) => {
                            const defaultData = allTimesheets?.filter((timeSheet) => !timeSheet?.IsMobile)?.find((timesheet) => timesheet?.Sequence === d)
                            const data = allTimesheets?.filter((timeSheet) => !timeSheet?.IsMobile)?.find((timesheet) =>
                                timesheet?.Sequence === d &&
                                timesheet?.Date === date
                            )

                            return data ? (
                                data
                            ) : allTimesheets?.filter((timeSheet) => !timeSheet?.IsMobile).length > 0 ? {
                                ...defaultData,
                                Id: 0,
                                Hour: "",
                                Date: date,
                                IndexId: dateIndex,
                                Notes: "",
                                IsSubmited: false
                            } : allFourites[index]
                                ? ({
                                    Comment: "",
                                    Date: date,
                                    FunctionId: allFourites[index]?.FunctionId,
                                    Hour: "",
                                    IsMobile: false,
                                    Hours: "00:00:00",
                                    Id: 0,
                                    IndexId: dateIndex,
                                    IsSubmited: false,
                                    ProjectId: allFourites[index]?.ProjectId,
                                    Sequence: d,
                                    ServiceCategoriesId: allFourites[index]?.ServiceCategoriesId,
                                    ServiceDisciplinesId: allFourites[index]?.ServiceDisciplinesId,
                                    User: currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN,
                                    Notes: ""
                                }) : ({
                                    Comment: "",
                                    Date: date,
                                    FunctionId: 0,
                                    Hour: "",
                                    Hours: "00:00:00",
                                    Id: 0,
                                    IndexId: dateIndex,
                                    IsMobile: false,
                                    IsSubmited: false,
                                    ProjectId: 0,
                                    Sequence: 1,
                                    ServiceCategoriesId: 0,
                                    ServiceDisciplinesId: 0,
                                    User: currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN,
                                    Notes: ""
                                })
                        })
                    })
                }
            } else {
                sequenceDateData.push({
                    Sequence: d,
                    IsMobile: false,
                    seqArray: weekDates?.map((date, dateIndex) => {
                        const defaultData = allTimesheets?.find((timesheet) => timesheet?.Sequence === d)
                        const data = allTimesheets?.find((timesheet) =>
                            timesheet?.Sequence === d &&
                            timesheet?.Date === date
                        )

                        return data ? (
                            data
                        ) : allTimesheets.length > 0 ? {
                            ...defaultData,
                            Id: 0,
                            Hour: "",
                            Date: date,
                            IndexId: dateIndex,
                            Notes: ""
                        } : allFourites[index]
                            ? ({
                                Comment: "",
                                Date: date,
                                FunctionId: allFourites[index]?.FunctionId,
                                Hour: "",
                                Hours: "00:00:00",
                                Id: 0,
                                IndexId: dateIndex,
                                IsMobile: false,
                                IsSubmited: false,
                                ProjectId: allFourites[index]?.ProjectId,
                                Sequence: d,
                                ServiceCategoriesId: allFourites[index]?.ServiceCategoriesId,
                                ServiceDisciplinesId: allFourites[index]?.ServiceDisciplinesId,
                                User: currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN,
                                Notes: ""
                            }) : ({
                                Comment: "",
                                Date: date,
                                FunctionId: 0,
                                Hour: "",
                                Hours: "00:00:00",
                                Id: 0,
                                IndexId: dateIndex,
                                IsMobile: false,
                                IsSubmited: false,
                                ProjectId: 0,
                                Sequence: 1,
                                ServiceCategoriesId: 0,
                                ServiceDisciplinesId: 0,
                                User: currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN,
                                Notes: ""
                            })
                    })
                })
            }
        })

        const checkIsSubmitedResponse = checkIsSubmitedMonthWise(sequenceDateData)

        const isHolidayCheck = checkIsSubmitedResponse?.map((seq) => ({
            ...seq,
            seqArray: seq?.seqArray?.map((data) => ({
                ...data,
                // Hour: allHolidays.find((holiday) => moment(holiday?.Date).format("YYYY-MM-DD") === data?.Date) ? "" : data?.Hour,
                IsHoliday: allHolidays.find((holiday) => moment(holiday?.Date).format("YYYY-MM-DD") === data?.Date) ? data?.Hour !== "" ? false : true : false,
                Holiday: allHolidays.find((holiday) => moment(holiday?.Date).format("YYYY-MM-DD") === data?.Date) ? allHolidays.find((holiday) => moment(holiday?.Date).format("YYYY-MM-DD") === data?.Date)?.Name : ""
            }))
        }))
        setSequenceData(isHolidayCheck)
    }, [allTimesheets, weekDates, currentUser?.Data?.Email, allFourites])

    useEffect(() => {
        console.log('currentUser', currentUser)
    }, [currentUser])

    // Disable Conditions
    useEffect(() => {
        const allIsSubmitted = sequenceData?.every((seq) =>
            seq?.seqArray.every((week) => week?.IsSubmited)
        )

        const anyIsSubmitted = sequenceData?.some((seq) =>
            seq?.seqArray.some((week) => week?.IsSubmited)
        )

        const dateRange = currentDateInRange()
        const submitMonth = moment(dateRange?.submitMonth, "MMMM").format("MM")
        const isSubmittedInMonth = sequenceData?.some((seq) =>
            seq?.seqArray.some((week) =>
                moment(week?.Date).format("MM") === submitMonth && week?.IsSubmited
            )
        )

        const lockedMonth = currentUser?.Data?.LockTimesheets?.length > 0 && currentUser?.Data?.LockTimesheets.some((period) => (
            moment(`${period?.Month}-${period?.Year}`, "MMM-YYYY").format("MM-YYYY") === moment(weekDates[0], "YYYY-MM-DD").format("MM-YYYY")
        ))

        setDisableCondition1(allIsSubmitted)
        setDisableCondition2(anyIsSubmitted)
        setDisableCondition3(isSubmittedInMonth)
        setLockPeriodDisableCondition(lockedMonth)
    }, [sequenceData])

    const timesheetPeriodDisable = (date) => {
        return currentUser?.Data?.LockTimesheets?.length > 0 && currentUser?.Data?.LockTimesheets.some((period) => (
            moment(`${period?.Month}-${period?.Year}`, "MMM-YYYY").format("MM-YYYY") === moment(date, "YYYY-MM-DD").format("MM-YYYY")
        ))
    }

    const handleEnableHoliday = (timesheet) => {
        const tempArray = sequenceData?.map((seq) => ({
            ...seq,
            seqArray: seq?.seqArray?.map((data) => ({
                ...data,
                IsHoliday: timesheet?.Sequence === seq?.Sequence && timesheet?.Date === data?.Date ? false : data?.IsHoliday
            }))
        }))
        setSequenceData(tempArray)
    }

    // submited months array
    const checkSubmitedMonths = (months) => {
        if (months !== null && months !== "") {
            const monthArray = months.split(",")
            setSubmitedMonthArray(monthArray)
        } else {
            setSubmitedMonthArray([])
        }
    }

    // Calculate total time date-wise
    const totalTimeByDate = sequenceData?.reduce((result, { seqArray }) => {
        seqArray?.forEach(({ Date, Hour }) => {
            // Convert blank strings to 0
            const hour = Hour === "" ? 0 : parseFloat(Hour);

            const dateEntry = result.find(entry => entry.Date === Date);
            if (dateEntry) {
                dateEntry.TotalTime += hour;
            } else {
                result.push({ Date, TotalTime: hour });
            }
        });
        return result;
    }, []);

    // Calculate week total time
    const totalOfTotalTime = totalTimeByDate?.reduce((total, { TotalTime }) => total + TotalTime, 0);

    // Copy Sequence
    const handleOnClickCopy = (seqArray) => {
        setSequenceData([
            ...sequenceData,
            {
                Sequence: Math.max(...removeDupe(sequenceData)) + 1,
                IsMobile: false,
                seqArray: seqArray?.map((data) => ({
                    ...data,
                    Id: 0,
                    Hour: data?.IsSubmited ? "" : data?.Hour,
                    Sequence: Math.max(...removeDupe(sequenceData)) + 1,
                    IsSubmited: data?.IsSubmited
                }))
            }
        ])
        let tempArray = [
            ...allTimesheetsResponse,
            ...seqArray?.map((d) => ({
                "Id": 0,
                "Date": d?.Date,
                "Hour": d?.IsSubmited ? "" : d?.Hour,
                "ProjectId": d?.ProjectId,
                "ServiceCategoriesId": d?.ServiceCategoriesId,
                "ServiceDisciplinesId": d?.ServiceDisciplinesId,
                "FunctionId": d?.FunctionId,
                "Comment": d?.Comment,
                "IsSubmited": d?.IsSubmited,
                "Sequence": Math.max(...removeDupe(sequenceData)) + 1,
                "User": d?.User,
                "IsMobile": d?.IsMobile,
                "Notes": d?.Notes
            }))
        ]
        setAllTimesheetsResponse(tempArray?.filter((d) => d?.Hour !== ""))
        saveWeekData(tempArray?.filter((d) => d?.Hour !== ""))
        setCheckAllTimesheets(tempArray?.filter((d) => d?.Hour !== ""))
    }

    // Delete Sequence
    const handleOnClickDelete = async (data) => {
        setIsLoading(true)

        let payload = [];
        data?.seqArray.forEach((e) => {
            if (e?.Id !== 0) {
                payload.push(e?.Id);
            }
        });

        if (payload.length > 0) {
            swal({
                title: 'Are you sure?',
                text: 'You will not be able to recover this timesheet!',
                icon: 'warning',
                buttons: ['Cancel', 'Yes, delete it!'],
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    deleteTimeSheet(payload).then((response) => {
                        if (response?.Success) {
                            toast.success("Timesheet deleted successfully")
                            GetAllTimesheets(userUpnPa && currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN)
                        }
                    }).catch((error) => {
                        console.warn("error", error)
                    }).finally(() => {
                        setIsLoading(false)
                    });
                } else {
                    setIsLoading(false);
                }
            });
        } else {
            setSequenceData(sequenceData?.filter((seq) => seq?.Sequence !== data?.Sequence))
            setAllTimesheetsResponse(allTimesheetsResponse?.filter((seq) => seq?.Sequence !== data?.Sequence))
            setCheckAllTimesheets(allTimesheetsResponse?.filter((seq) => seq?.Sequence !== data?.Sequence))
            setIsLoading(false)
        }
    }

    // Add New Row
    const handleOnClickAddRow = () => {
        const tempSequence = [
            ...sequenceData,
            {
                Sequence: sequenceData.length > 0 ? Math.max(...removeDupe(sequenceData)) + 1 : 1,
                IsMobile: false,
                IsWeekSubmited: weekDates?.map((date, dateIndex) => {
                    return {
                        Comment: null,
                        Date: date,
                        FunctionId: 0,
                        Hour: "",
                        Hours: "00:00:00",
                        IsMobile: false,
                        Id: 0,
                        IndexId: dateIndex,
                        IsSubmited: false,
                        Notes: "",
                        ProjectId: 0,
                        Sequence: sequenceData.length > 0 ? Math.max(...removeDupe(sequenceData)) + 1 : 1,
                        ServiceCategoriesId: 0,
                        ServiceDisciplinesId: 0,
                        User: currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN,
                    }
                }).some(entry => !entry.IsSubmited) ? false : true,
                seqArray: weekDates?.map((date, dateIndex) => {
                    return {
                        Comment: null,
                        Date: date,
                        FunctionId: 0,
                        Hour: "",
                        Hours: "00:00:00",
                        IsMobile: false,
                        Id: 0,
                        IndexId: dateIndex,
                        IsSubmited: false,
                        Notes: "",
                        ProjectId: 0,
                        Sequence: sequenceData.length > 0 ? Math.max(...removeDupe(sequenceData)) + 1 : 1,
                        ServiceCategoriesId: 0,
                        ServiceDisciplinesId: 0,
                        User: currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN,
                    }
                })
            }
        ]

        const submitedMonth = tempSequence?.map((seq) => ({
            ...seq,
            seqArray: seq?.seqArray?.map((data) => ({
                ...data,
                IsSubmited: submitedMonthArray?.length > 0
                    ? submitedMonthArray?.map((month) => moment(month, "MMMM").format("MM") === moment(data?.Date, "YYYY-MM-DD").format("MM"))[0]
                        ? true
                        : false
                    : false
            }))
        }))

        const isHolidayCheck = submitedMonth?.map((seq) => ({
            ...seq,
            seqArray: seq?.seqArray?.map((data) => ({
                ...data,
                // Hour: allHolidays.find((holiday) => moment(holiday?.Date).format("YYYY-MM-DD") === data?.Date) ? "" : data?.Hour,
                IsHoliday: allHolidays.find((holiday) => moment(holiday?.Date).format("YYYY-MM-DD") === data?.Date) ? data?.Hour !== "" ? false : true : false,
                Holiday: allHolidays.find((holiday) => moment(holiday?.Date).format("YYYY-MM-DD") === data?.Date) ? allHolidays.find((holiday) => moment(holiday?.Date).format("YYYY-MM-DD") === data?.Date)?.Name : ""
            }))
        }))

        const defaultHolidayDataSet = isHolidayCheck.map((item) => ({
            ...item,
            DefaultHoliday: item.seqArray.some(seqItem => seqItem.IsHoliday),
            seqArray: item.seqArray.map((data) => ({
                ...data,
                FunctionId: item.hasOwnProperty("IsWeekSubmited") ? item.seqArray.some(seqItem => seqItem.IsHoliday) ? defaultHolidaySelection?.FunctionId : data?.FunctionId : data?.FunctionId,
                ServiceCategoriesId: item.hasOwnProperty("IsWeekSubmited") ? item.seqArray.some(seqItem => seqItem.IsHoliday) ? defaultHolidaySelection?.ServiceCategoriesId : data?.ServiceCategoriesId : data?.ServiceCategoriesId,
                ServiceDisciplinesId: item.hasOwnProperty("IsWeekSubmited") ? item.seqArray.some(seqItem => seqItem.IsHoliday) ? defaultHolidaySelection?.ServiceDisciplinesId : data?.ServiceDisciplinesId : data?.ServiceDisciplinesId,
            }))
        }))
        setSequenceData(defaultHolidayDataSet)
    }

    // Open & Set Modal Data
    const handleSetData = (data) => {
        setInputSequence(data?.Sequence)
        setInputIsMobile(data?.IsMobile)
        setInputFunction(data?.seqArray[0]?.FunctionId)
        setInputServiceCategory(data?.seqArray[0]?.ServiceCategoriesId)
        setInputServiceDiscipline(data?.seqArray[0]?.ServiceDisciplinesId)
        setInputProject(data?.seqArray[0]?.ProjectId)
        setInputComment(data?.seqArray[0]?.Comment ?? "")
    }

    // Update Notes to sequenceData
    const handleSetNotes = () => {
        const array = sequenceData?.map((seq) => ({
            ...seq,
            seqArray: seq?.seqArray?.map((data) => ({
                ...data,
                Notes: inputSeqData?.Sequence === seq?.Sequence && inputSeqData?.IsMobile === seq?.IsMobile ? inputSeqData?.Date === data?.Date ? inputNotes : data?.Notes : data?.Notes
            }))
        }))
        setSequenceData(array)

        setAllTimesheetsResponse(
            allTimesheetsResponse?.map((prev) => {
                if (prev?.Id === inputSeqData?.Id) {
                    return ({
                        Comment: inputSeqData?.Comment,
                        Date: moment(inputSeqData?.Date).format("YYYY-MM-DD"),
                        FunctionId: inputSeqData?.FunctionId,
                        Hour: inputSeqData?.Hour,
                        Hours: inputSeqData?.Hours,
                        IsMobile: inputSeqData?.IsMobile,
                        Id: inputSeqData?.Id,
                        IsSubmited: inputSeqData?.IsSubmited,
                        ProjectId: inputSeqData?.ProjectId,
                        Sequence: inputSeqData?.Sequence,
                        ServiceCategoriesId: inputSeqData?.ServiceCategoriesId,
                        ServiceDisciplinesId: inputSeqData?.ServiceDisciplinesId,
                        User: currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN,
                        Notes: inputNotes,
                    })
                } else {
                    return prev
                }
            })
        )

        setCheckAllTimesheets(
            allTimesheetsResponse?.map((prev) => {
                if (prev?.Id === inputSeqData?.Id) {
                    return ({
                        Comment: inputSeqData?.Comment,
                        Date: moment(inputSeqData?.Date).format("YYYY-MM-DD"),
                        FunctionId: inputSeqData?.FunctionId,
                        Hour: inputSeqData?.Hour,
                        Hours: inputSeqData?.Hours,
                        IsMobile: inputSeqData?.IsMobile,
                        Id: inputSeqData?.Id,
                        IsSubmited: inputSeqData?.IsSubmited,
                        ProjectId: inputSeqData?.ProjectId,
                        Sequence: inputSeqData?.Sequence,
                        ServiceCategoriesId: inputSeqData?.ServiceCategoriesId,
                        ServiceDisciplinesId: inputSeqData?.ServiceDisciplinesId,
                        User: currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN,
                        Notes: inputNotes,
                    })
                } else {
                    return prev
                }
            })
        )
    }

    // Input Time
    const handleOnTimeInput = (seqData, date, value) => {

        const regex = new RegExp(`^[0-9,.-]+$`)

        if (value?.length > 0) {
            if (regex.test(value)) {
                const array = sequenceData?.map((seq) => ({
                    ...seq,
                    seqArray: seq?.seqArray?.map((data) => ({
                        ...data,
                        Hour: seqData?.Sequence === seq?.Sequence && seqData?.IsMobile === seq?.IsMobile ? data?.Date === date ? value.length > 0 ? value.replace(",", ".") : "" : data?.Hour : data?.Hour
                    }))
                }))
                setSequenceData(array)
            }
        } else {
            const array = sequenceData?.map((seq) => ({
                ...seq,
                seqArray: seq?.seqArray?.map((data) => ({
                    ...data,
                    Hour: seqData?.Sequence === seq?.Sequence && seqData?.IsMobile === seq?.IsMobile ? data?.Date === date ? value.length === 0 ? 0 : "" : data?.Hour : data?.Hour
                }))
            }))
            setSequenceData(array)
        }
    }

    // Check value change
    const isValueChange = (timesheet) => {
        const data = allTimesheetsResponse?.filter((d) => d?.Sequence === timesheet?.Sequence && d?.Date === timesheet?.Date && d?.Hour === timesheet?.Hour)
        return data.length > 0 ? true : false
    }

    // Auto Save on Time Change
    const handleAutoSaveOnTimeChange = (timesheet, elementId) => {
        if (timesheet?.Hour !== "") {
            if (isValueChange(timesheet) === false) {

                const hasDot = timesheet?.Hour !== 0 ? timesheet?.Hour.includes(".") : false
                const hasComma = timesheet?.Hour !== 0 ? timesheet?.Hour.includes(",") : false

                if (timesheet?.Hour !== 0 && (hasDot || hasComma)) {
                    const time = timesheet?.Hour.split(hasDot ? "." : ",")
                    const Int = Number(time[0])
                    const Des = time[1]?.length > 0 ? Number(time[1]) : undefined

                    const isValidInt = Int >= 0 && Int <= 999
                    const isValidDes = Des !== undefined && time[1]?.toString()?.length <= 2
                    // const isValidDes = Des !== undefined && time[1]?.toString()?.length <= 2 && (Des === 0 || Des === 0o0 || Des === 25 || Des === 5 || Des === 50 || Des === 75)

                    if (isValidInt && isValidDes) {
                        AutoSaveTimeSheet(timesheet)
                    } else {
                        if (!isValidInt) {
                            toast.error("You can only enter upto 999")
                            const element = document.getElementById(elementId)
                            element && element?.focus()
                        } else {
                            toast.error("You can only enter upto 99 as decimals")
                            const element = document.getElementById(elementId)
                            element && element?.focus()
                        }
                    }
                } else {
                    if (timesheet?.Hour <= 999) {
                        AutoSaveTimeSheet(timesheet)
                    } else {
                        toast.error("You can only enter upto 999")
                        const element = document.getElementById(elementId)
                        element && element?.focus()
                    }
                }
            }
        }
    }

    const AutoSaveTimeSheet = async (timesheet) => {
        let payload = {}
        if (timesheet?.Id === 0) {
            payload = {
                "Date": timesheet?.Date,
                "Hour": timesheet?.Hour !== 0 ? timesheet?.Hour.replace(",", ".") : 0,
                "Hours": timesheet?.Hours,
                "IsMobile": timesheet?.IsMobile,
                "ProjectId": timesheet?.ProjectId,
                "ServiceCategoriesId": timesheet?.ServiceCategoriesId,
                "ServiceDisciplinesId": timesheet?.ServiceDisciplinesId,
                "FunctionId": timesheet?.FunctionId,
                "Comment": timesheet?.Comment,
                "IsSubmited": timesheet?.IsSubmited,
                "Sequence": timesheet?.Sequence,
                "User": timesheet?.User,
                "Notes": timesheet?.Notes
            }
        } else {
            payload = {
                "Id": timesheet?.Id,
                "Date": timesheet?.Date,
                "Hour": timesheet?.Hour !== 0 ? timesheet?.Hour.replace(",", ".") : 0,
                "Hours": timesheet?.Hours,
                "ProjectId": timesheet?.ProjectId,
                "ServiceCategoriesId": timesheet?.ServiceCategoriesId,
                "ServiceDisciplinesId": timesheet?.ServiceDisciplinesId,
                "FunctionId": timesheet?.FunctionId,
                "Comment": timesheet?.Comment,
                "IsSubmited": timesheet?.IsSubmited,
                "Sequence": timesheet?.Sequence,
                "User": timesheet?.User,
                "IsMobile": timesheet?.IsMobile,
                "Notes": timesheet?.Notes
            }
        }

        if (payload?.FunctionId !== 0 && payload?.ServiceCategoriesId !== 0 && payload?.ServiceDisciplinesId !== 0) {
            setIsAutoSaveing(true)
            await createUpdateTimesheet(payload).then((response) => {
                if (response?.Success) {
                    const array = sequenceData?.map((seq) => ({
                        ...seq,
                        seqArray: seq?.seqArray?.map((data) => ({
                            ...data,
                            Comment: timesheet?.Sequence === seq?.Sequence && timesheet?.IsMobile === data?.IsMobile ? timesheet?.Date === data?.Date ? response?.Data?.Comment : data?.Comment : data?.Comment,
                            Date: timesheet?.Sequence === seq?.Sequence && timesheet?.IsMobile === data?.IsMobile ? timesheet?.Date === data?.Date ? moment(response?.Data?.Date).format("YYYY-MM-DD") : data?.Date : data?.Date,
                            FunctionId: timesheet?.Sequence === seq?.Sequence && timesheet?.IsMobile === data?.IsMobile ? data?.Date === timesheet?.Date ? response?.Data?.FunctionId : data?.FunctionId : data?.FunctionId,
                            Hour: timesheet?.Sequence === seq?.Sequence && timesheet?.IsMobile === data?.IsMobile ? data?.Date === timesheet?.Date ? response?.Data?.Hour : data?.Hour : data?.Hour,
                            Hours: timesheet?.Sequence === seq?.Sequence && timesheet?.IsMobile === data?.IsMobile ? data?.Date === timesheet?.Date ? response?.Data?.Hours : data?.Hours : data?.Hours,
                            IsMobile: timesheet?.Sequence === seq?.Sequence && timesheet?.IsMobile === data?.IsMobile ? data?.Date === timesheet?.Date ? response?.Data?.IsMobile : data?.IsMobile : data?.IsMobile,
                            Id: timesheet?.Sequence === seq?.Sequence && timesheet?.IsMobile === data?.IsMobile ? data?.Date === timesheet?.Date ? response?.Data?.Id : data?.Id : data?.Id,
                            IsSubmited: timesheet?.Sequence === seq?.Sequence && timesheet?.IsMobile === data?.IsMobile ? data?.Date === timesheet?.Date ? response?.Data?.IsSubmited : data?.IsSubmited : data?.IsSubmited,
                            ProjectId: timesheet?.Sequence === seq?.Sequence && timesheet?.IsMobile === data?.IsMobile ? data?.Date === timesheet?.Date ? response?.Data?.ProjectId : data?.ProjectId : data?.ProjectId,
                            Sequence: timesheet?.Sequence === seq?.Sequence && timesheet?.IsMobile === data?.IsMobile ? data?.Date === timesheet?.Date ? response?.Data?.Sequence : data?.Sequence : data?.Sequence,
                            ServiceCategoriesId: timesheet?.Sequence === seq?.Sequence && timesheet?.IsMobile === data?.IsMobile ? data?.Date === timesheet?.Date ? response?.Data?.ServiceCategoriesId : data?.ServiceCategoriesId : data?.ServiceCategoriesId,
                            ServiceDisciplinesId: timesheet?.Sequence === seq?.Sequence && timesheet?.IsMobile === data?.IsMobile ? data?.Date === timesheet?.Date ? response?.Data?.ServiceDisciplinesId : data?.ServiceDisciplinesId : data?.ServiceDisciplinesId,
                            User: timesheet?.Sequence === seq?.Sequence && timesheet?.IsMobile === data?.IsMobile ? data?.Date === timesheet?.Date ? response?.Data?.User : data?.User : data?.User,
                            Notes: timesheet?.Sequence === seq?.Sequence && timesheet?.IsMobile === data?.IsMobile ? data?.Date === timesheet?.Date ? response?.Data?.Notes : data?.Notes : data?.Notes,
                        }))
                    }))
                    setSequenceData(array)
                    toast.success("Data saved successfully!")

                    if (timesheet?.Id === 0) {
                        setAllTimesheetsResponse([
                            ...allTimesheetsResponse,
                            {
                                Comment: response?.Data?.Comment,
                                Date: moment(response?.Data?.Date).format("YYYY-MM-DD"),
                                FunctionId: response?.Data?.FunctionId,
                                Hour: response?.Data?.Hour,
                                Hours: response?.Data?.Hours,
                                IsMobile: response?.Data?.IsMobile,
                                Id: response?.Data?.Id,
                                IsSubmited: response?.Data?.IsSubmited,
                                ProjectId: response?.Data?.ProjectId,
                                Sequence: response?.Data?.Sequence,
                                ServiceCategoriesId: response?.Data?.ServiceCategoriesId,
                                ServiceDisciplinesId: response?.Data?.ServiceDisciplinesId,
                                User: response?.Data?.User,
                                Notes: response?.Data?.Notes,
                            }
                        ])
                        setCheckAllTimesheets([
                            ...allTimesheetsResponse,
                            {
                                Comment: response?.Data?.Comment,
                                Date: moment(response?.Data?.Date).format("YYYY-MM-DD"),
                                FunctionId: response?.Data?.FunctionId,
                                Hour: response?.Data?.Hour,
                                Hours: response?.Data?.Hours,
                                IsMobile: response?.Data?.IsMobile,
                                Id: response?.Data?.Id,
                                IsSubmited: response?.Data?.IsSubmited,
                                ProjectId: response?.Data?.ProjectId,
                                Sequence: response?.Data?.Sequence,
                                ServiceCategoriesId: response?.Data?.ServiceCategoriesId,
                                ServiceDisciplinesId: response?.Data?.ServiceDisciplinesId,
                                User: response?.Data?.User,
                                Notes: response?.Data?.Notes,
                            }
                        ])
                    } else {
                        setAllTimesheetsResponse(
                            allTimesheetsResponse?.map((prev) => {
                                if (prev?.Id === response?.Data?.Id) {
                                    return ({
                                        Comment: response?.Data?.Comment,
                                        Date: moment(response?.Data?.Date).format("YYYY-MM-DD"),
                                        FunctionId: response?.Data?.FunctionId,
                                        Hour: response?.Data?.Hour,
                                        Hours: response?.Data?.Hours,
                                        IsMobile: response?.Data?.IsMobile,
                                        Id: response?.Data?.Id,
                                        IsSubmited: response?.Data?.IsSubmited,
                                        ProjectId: response?.Data?.ProjectId,
                                        Sequence: response?.Data?.Sequence,
                                        ServiceCategoriesId: response?.Data?.ServiceCategoriesId,
                                        ServiceDisciplinesId: response?.Data?.ServiceDisciplinesId,
                                        User: response?.Data?.User,
                                        Notes: response?.Data?.Notes,
                                    })
                                } else {
                                    return prev
                                }
                            })
                        )
                        setCheckAllTimesheets(
                            allTimesheetsResponse?.map((prev) => {
                                if (prev?.Id === response?.Data?.Id) {
                                    return ({
                                        Comment: response?.Data?.Comment,
                                        Date: moment(response?.Data?.Date).format("YYYY-MM-DD"),
                                        FunctionId: response?.Data?.FunctionId,
                                        Hour: response?.Data?.Hour,
                                        Hours: response?.Data?.Hours,
                                        IsMobile: response?.Data?.IsMobile,
                                        Id: response?.Data?.Id,
                                        IsSubmited: response?.Data?.IsSubmited,
                                        ProjectId: response?.Data?.ProjectId,
                                        Sequence: response?.Data?.Sequence,
                                        ServiceCategoriesId: response?.Data?.ServiceCategoriesId,
                                        ServiceDisciplinesId: response?.Data?.ServiceDisciplinesId,
                                        User: response?.Data?.User,
                                        Notes: response?.Data?.Notes,
                                    })
                                } else {
                                    return prev
                                }
                            })
                        )
                    }
                }
            }).catch((error) => {
                console.warn('error', error)
                toast.error(error)
            }).finally(() => {
                setIsAutoSaveing(false)
            });
        } else {
            if (payload?.FunctionId === 0) {
                toast.error("Function is required!")
                const array = sequenceData?.map((seq) => ({
                    ...seq,
                    seqArray: seq?.seqArray?.map((data) => ({
                        ...data,
                        Hour: payload?.Sequence === seq?.Sequence && data?.Date === payload?.Date ? "" : data?.Hour
                    }))
                }))
                setSequenceData(array)
            } else if (payload?.ServiceCategoriesId === 0) {
                toast.error("Service Categories is required!")
                const array = sequenceData?.map((seq) => ({
                    ...seq,
                    seqArray: seq?.seqArray?.map((data) => ({
                        ...data,
                        Hour: payload?.Sequence === seq?.Sequence && data?.Date === payload?.Date ? "" : data?.Hour
                    }))
                }))
                setSequenceData(array)
            } else if (payload?.ServiceDisciplinesId === 0) {
                toast.error("Service Disciplines is required!")
                const array = sequenceData?.map((seq) => ({
                    ...seq,
                    seqArray: seq?.seqArray?.map((data) => ({
                        ...data,
                        Hour: payload?.Sequence === seq?.Sequence && data?.Date === payload?.Date ? "" : data?.Hour
                    }))
                }))
                setSequenceData(array)
            }
        }
    }

    const checkIsValidTime = (seqData) => {
        if (seqData?.Hour !== "") {
            if (isValueChange(seqData) === false) {

                const hasDot = seqData?.Hour.length > 0 && seqData?.Hour !== 0 ? seqData?.Hour.includes(".") : false
                const hasComma = seqData?.Hour.length > 0 && seqData?.Hour !== 0 ? seqData?.Hour.includes(",") : false

                if (seqData?.Hour !== 0 && (hasDot || hasComma)) {
                    const time = seqData?.Hour.split(hasDot ? "." : ",")
                    const Int = Number(time[0])
                    const Des = time[1]?.length > 0 ? Number(time[1]) : undefined

                    const isValidInt = Int >= 0 && Int <= 999
                    const isValidDes = Des !== undefined && time[1]?.toString()?.length <= 2
                    // const isValidDes = Des !== undefined && time[1]?.toString()?.length <= 2 && (Des === 0 || Des === 0o0 || Des === 25 || Des === 5 || Des === 50 || Des === 75)

                    if (isValidInt && isValidDes) {
                        return true
                    }
                    else {
                        if (!isValidInt) {
                            return false
                        } else {
                            return false
                        }
                    }
                } else {
                    if (seqData?.Hour <= 999) {
                        return true
                    } else {
                        return false
                    }
                }
            } else {
                return true
            }
        } else {
            return true
        }
    }

    // Save Modal Data
    const handleSaveData = () => {
        if (inputFunction !== 0 && inputServiceCategory !== 0 && inputServiceDiscipline !== 0 &&
            (allServiceCategories?.filter((cate) => cate?.Id === inputServiceCategory)[0]?.ProjectSelection ? inputProject !== 0 : true)) {
            const array = sequenceData?.map((seq) => ({
                ...seq,
                seqArray: seq?.seqArray?.map((data) => ({
                    ...data,
                    Comment: seq?.Sequence === inputSequence && seq?.IsMobile === inputIsMobile ? inputComment : data?.Comment,
                    FunctionId: seq?.Sequence === inputSequence && seq?.IsMobile === inputIsMobile ? inputFunction : data?.FunctionId,
                    ServiceCategoriesId: seq?.Sequence === inputSequence && seq?.IsMobile === inputIsMobile ? inputServiceCategory : data?.ServiceCategoriesId,
                    ServiceDisciplinesId: seq?.Sequence === inputSequence && seq?.IsMobile === inputIsMobile ? inputServiceDiscipline : data?.ServiceDisciplinesId,
                    ProjectId: seq?.Sequence === inputSequence && seq?.IsMobile === inputIsMobile ? inputProject : data?.ProjectId
                }))
            }))
            setSequenceData(array)

            const tempArray = allTimesheetsResponse?.map((data) => ({
                ...data,
                Comment: data?.Sequence === inputSequence && data?.IsMobile === inputIsMobile ? inputComment : data?.Comment,
                FunctionId: data?.Sequence === inputSequence && data?.IsMobile === inputIsMobile ? inputFunction : data?.FunctionId,
                ServiceCategoriesId: data?.Sequence === inputSequence && data?.IsMobile === inputIsMobile ? inputServiceCategory : data?.ServiceCategoriesId,
                ServiceDisciplinesId: data?.Sequence === inputSequence && data?.IsMobile === inputIsMobile ? inputServiceDiscipline : data?.ServiceDisciplinesId,
                ProjectId: data?.Sequence === inputSequence && data?.IsMobile === inputIsMobile ? inputProject : data?.ProjectId
            }))
            setAllTimesheetsResponse(tempArray)
            setCheckAllTimesheets(tempArray)
        } else {
            if (inputFunction === 0) {
                toast.error("Function is required!")
            } else if (inputServiceCategory === 0) {
                toast.error("Service Categories is required!")
            } else if (inputServiceDiscipline === 0) {
                toast.error("Service Disciplines is required!")
            } else if (allServiceCategories?.filter((cate) => cate?.Id === inputServiceCategory)[0]?.ProjectSelection && inputProject === 0) {
                toast.error("Project is required!")
            }
        }
    }

    // Save Week Data
    const saveWeekData = (weekData) => {
        const payload = weekData ?? allTimesheetsResponse?.filter((d) => d?.Hour !== "")

        if (payload.length > 0) {
            const isValid = sequenceData?.map((d) => !d?.seqArray?.some((s) => !checkIsValidTime(s)))

            if (!isValid?.some((v) => !v)) {
                setIsLoading(true);
                insertUpdateTimesheets(payload).then((response) => {
                    if (typeof response?.Data === "object" && response?.Success) {
                        toast.success("Data saved successfully!")
                        GetAllTimesheets(userUpnPa && currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN)
                    } else {
                        toast.error(response?.Message)
                    }
                }).catch((error) => {
                    console.warn('error', error)
                }).finally(() => {
                    setIsLoading(false)
                });
            } else {
                toast.error("Please enter Hour integer upto 999 and decimal upto 99!")
            }
        } else {
            toast.error("No data to save!")
        }
    }

    // Submit Week Data
    const submitWeekData = () => {
        const startOfMonth = moment(weekDates[0]).startOf('month').format('YYYY-MM-DD');
        const endOfMonth = moment(weekDates[0]).endOf('month').format('YYYY-MM-DD');

        const payload = {
            "StartDate": startOfMonth,
            "EndDate": endOfMonth,
            "User": currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN
        }

        swal({
            title: "Are you sure?",
            text: "You will not be able to unsubmit this timesheets!",
            icon: "warning",
            buttons: ['Cancel', 'Yes, submit it!'],
            className: "custom-swal",
            successMode: true,
        }).then((willSubmit) => {
            if (willSubmit) {
                setIsLoading(true);
                submitTimesheet(payload).then((response) => {
                    if (typeof response?.Data === "object" && response?.Success) {
                        toast.success("Data saved successfully!")
                        GetAllTimesheets(userUpnPa && currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN)
                    } else {
                        toast.error(response?.Message)
                    }
                }).catch((error) => {
                    console.warn('error', error)
                }).finally(() => {
                    setIsLoading(false)
                });
            }
        })
    }

    // Keep watch on window width
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 480);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleDateChange = (direction) => {
        if (direction === "prev") {
            setCalendarDate((prev) => moment(prev).subtract(isMobileView ? 1 : 7, "d").format("YYYY-MM-DD"));
        } else if (direction === "next") {
            setCalendarDate((prev) => moment(prev).add(isMobileView ? 1 : 7, "d").format("YYYY-MM-DD"));
        } else {
            setCalendarDate(moment()._d);
        }
    };

    const CustomDatePicker = forwardRef(({ value, onClick }, ref) =>
        <div className="cal-icon">
            <button type="submit" onClick={onClick} ref={ref} className="btn btn-primary-w btn-icon2 cal-btn p-3">
                <i className="fa-regular fa-calendar" />
            </button>
        </div>
    );

    const scrollToDiv = () => {
        var main = document.getElementById("pagetitle");
        main && main?.scrollIntoView({ behavior: "smooth", block: "end" });
    }

    // Show / Hide submit button
    const currentDateInRange = () => {
        const endOfMonth = moment(weekDates[0]).endOf('month').format('YYYY-MM-DD');
        const month = moment(endOfMonth, 'YYYY-MM-DD').format('MMMM');

        return { submitShow: weekDates?.find((date) => date === endOfMonth) ? true : false, submitMonth: month }
    }

    //used in change on copy from modal
    const CurrentWeek = () => {
        let dates = []

        const weekStart = isMobileView
            ? moment(checkDate).format("YYYY-MM-DD")
            : moment(checkDate).startOf("isoWeek").format("YYYY-MM-DD")
        Array.from(Array(isMobileView ? 1 : 7), (_, i) => i + 1)?.forEach((_, i) => {
            dates.push(moment(weekStart).add(i, "d").format("YYYY-MM-DD"))
        })

        setCurrentWeek(dates)
    }

    //dates for copy from modal 
    const weekCheckDates = useMemo(() => {
        let dates = []

        const weekStart = isMobileView
            ? moment(checkDate).format("YYYY-MM-DD")
            : moment(checkDate).startOf("isoWeek").format("YYYY-MM-DD")
        Array.from(Array(isMobileView ? 1 : 7), (_, i) => i + 1)?.forEach((_, i) => {
            dates.push(moment(weekStart).add(i, "d").format("YYYY-MM-DD"))
        })

        return dates
    }, [checkDate, isMobileView])

    //timesheet for copy from modal
    useEffect(() => {
        let sequenceDateData = []
        removeDupe(checkAllTimesheets)?.forEach((d, index) => {
            const currentSequenceTotalArray = checkAllTimesheets?.filter((timeSheet) => timeSheet?.Sequence === d)
            const isMobileTrueArray = currentSequenceTotalArray?.filter((timeSheet) => timeSheet?.IsMobile)
            const isMobileFalseArray = currentSequenceTotalArray?.filter((timeSheet) => !timeSheet?.IsMobile)

            if (checkAllTimesheets.length > 0) {
                if (isMobileTrueArray?.length > 0) {
                    sequenceDateData.push({
                        Sequence: d,
                        IsMobile: true,
                        seqArray: weekCheckDates?.map((date, dateIndex) => {
                            const defaultData = checkAllTimesheets?.filter((timeSheet) => timeSheet?.IsMobile)?.find((timesheet) => timesheet?.Sequence === d)
                            const data = checkAllTimesheets?.filter((timeSheet) => timeSheet?.IsMobile)?.find((timesheet) =>
                                timesheet?.Sequence === d &&
                                timesheet?.Date === date
                            )

                            return data ? (
                                data
                            ) : checkAllTimesheets?.filter((timeSheet) => timeSheet?.IsMobile).length > 0 ? {
                                ...defaultData,
                                Id: 0,
                                Hour: "",
                                Date: date,
                                IndexId: dateIndex,
                                Notes: ""
                            } : allFourites[index]
                                ? ({
                                    Comment: "",
                                    Date: date,
                                    FunctionId: allFourites[index]?.FunctionId,
                                    Hour: "",
                                    Id: 0,
                                    IndexId: dateIndex,
                                    IsSubmited: false,
                                    ProjectId: allFourites[index]?.ProjectId,
                                    Sequence: d,
                                    ServiceCategoriesId: allFourites[index]?.ServiceCategoriesId,
                                    ServiceDisciplinesId: allFourites[index]?.ServiceDisciplinesId,
                                    User: currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN,
                                    Notes: ""
                                }) : ({
                                    Comment: "",
                                    Date: date,
                                    FunctionId: 0,
                                    Hour: "",
                                    Id: 0,
                                    IndexId: dateIndex,
                                    IsSubmited: false,
                                    ProjectId: 0,
                                    Sequence: 1,
                                    ServiceCategoriesId: 0,
                                    ServiceDisciplinesId: 0,
                                    User: currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN,
                                    Notes: ""
                                })
                        })
                    })
                }

                if (isMobileFalseArray?.length > 0) {
                    sequenceDateData.push({
                        Sequence: d,
                        IsMobile: false,
                        seqArray: weekCheckDates?.map((date, dateIndex) => {
                            const defaultData = checkAllTimesheets?.filter((timeSheet) => !timeSheet?.IsMobile)?.find((timesheet) => timesheet?.Sequence === d)
                            const data = checkAllTimesheets?.filter((timeSheet) => !timeSheet?.IsMobile)?.find((timesheet) =>
                                timesheet?.Sequence === d &&
                                timesheet?.Date === date
                            )

                            return data ? (
                                data
                            ) : checkAllTimesheets?.filter((timeSheet) => !timeSheet?.IsMobile).length > 0 ? {
                                ...defaultData,
                                Id: 0,
                                Hour: "",
                                Date: date,
                                IndexId: dateIndex,
                                Notes: ""
                            } : allFourites[index]
                                ? ({
                                    Comment: "",
                                    Date: date,
                                    FunctionId: allFourites[index]?.FunctionId,
                                    Hour: "",
                                    Id: 0,
                                    IndexId: dateIndex,
                                    IsSubmited: false,
                                    ProjectId: allFourites[index]?.ProjectId,
                                    Sequence: d,
                                    ServiceCategoriesId: allFourites[index]?.ServiceCategoriesId,
                                    ServiceDisciplinesId: allFourites[index]?.ServiceDisciplinesId,
                                    User: currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN,
                                    Notes: ""
                                }) : ({
                                    Comment: "",
                                    Date: date,
                                    FunctionId: 0,
                                    Hour: "",
                                    Id: 0,
                                    IndexId: dateIndex,
                                    IsSubmited: false,
                                    ProjectId: 0,
                                    Sequence: 1,
                                    ServiceCategoriesId: 0,
                                    ServiceDisciplinesId: 0,
                                    User: currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN,
                                    Notes: ""
                                })
                        })
                    })
                }
            } else {
                sequenceDateData.push({
                    Sequence: d,
                    IsMobile: false,
                    seqArray: weekCheckDates?.map((date, dateIndex) => {
                        const defaultData = checkAllTimesheets?.find((timesheet) => timesheet?.Sequence === d)
                        const data = checkAllTimesheets?.find((timesheet) =>
                            timesheet?.Sequence === d &&
                            timesheet?.Date === date
                        )

                        return data ? (
                            data
                        ) : checkAllTimesheets.length > 0 ? {
                            ...defaultData,
                            Id: 0,
                            Hour: "",
                            Date: date,
                            IndexId: dateIndex,
                            Notes: ""
                        } : allFourites[index]
                            ? ({
                                Comment: "",
                                Date: date,
                                FunctionId: allFourites[index]?.FunctionId,
                                Hour: "",
                                Id: 0,
                                IndexId: dateIndex,
                                IsSubmited: false,
                                ProjectId: allFourites[index]?.ProjectId,
                                Sequence: d,
                                ServiceCategoriesId: allFourites[index]?.ServiceCategoriesId,
                                ServiceDisciplinesId: allFourites[index]?.ServiceDisciplinesId,
                                User: currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN,
                                Notes: ""
                            }) : ({
                                Comment: "",
                                Date: date,
                                FunctionId: 0,
                                Hour: "",
                                Id: 0,
                                IndexId: dateIndex,
                                IsSubmited: false,
                                ProjectId: 0,
                                Sequence: 1,
                                ServiceCategoriesId: 0,
                                ServiceDisciplinesId: 0,
                                User: currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN,
                                Notes: ""
                            })
                    })
                })
            }
        })

        setSequenceCheckData(sequenceDateData)
    }, [checkAllTimesheets, weekCheckDates, currentUser?.Data?.Email])

    //copy from modal
    const handleCheckCopy = () => {
        const d = checkArray?.map((q) =>
            ({ ...q, seqArray: q?.seqArray?.map((k, i) => ({ ...k, Date: weekDates[i] })) }))
            ?.map((s, i) => ({
                ...s,
                Sequence: Math.max(...removeDupe(sequenceData)) + 1 + i,
                seqArray: s?.seqArray?.map((d) => ({
                    ...d,
                    Id: 0,
                    Hour: d?.IsSubmited ? "" : d?.Hour,
                    IsSubmited: false,
                    Sequence: Math.max(...removeDupe(sequenceData)) + 1 + i
                }))
            }))

        let p = []
        d.map((q) => q?.seqArray?.map((r) => p.push({ ...r, Id: 0 })))

        const temp = [...sequenceData, ...d]
        // const isHolidayCheck = temp?.map((seq) => ({
        //     ...seq,
        //     seqArray: seq?.seqArray?.map((data) => ({
        //         ...data,
        //         IsHoliday: allHolidays.find((holiday) => moment(holiday?.Date).format("YYYY-MM-DD") === data?.Date) ? true : false,
        //         Holiday: allHolidays.find((holiday) => moment(holiday?.Date).format("YYYY-MM-DD") === data?.Date) ? allHolidays.find((holiday) => moment(holiday?.Date).format("YYYY-MM-DD") === data?.Date)?.Name : ""
        //     }))
        // }))
        const isHolidayCheck = temp?.map((seq) => ({
            ...seq,
            seqArray: seq?.seqArray?.map((data) => ({
                ...data,
                // Hour: allHolidays.find((holiday) => moment(holiday?.Date).format("YYYY-MM-DD") === data?.Date) ? "" : data?.Hour,
                IsHoliday: allHolidays.find((holiday) => moment(holiday?.Date).format("YYYY-MM-DD") === data?.Date) ? data?.Hour !== "" ? false : true : false,
                Holiday: allHolidays.find((holiday) => moment(holiday?.Date).format("YYYY-MM-DD") === data?.Date) ? allHolidays.find((holiday) => moment(holiday?.Date).format("YYYY-MM-DD") === data?.Date)?.Name : ""
            }))
        }))
        setSequenceData(isHolidayCheck)
        setAllTimesheetsResponse([...allTimesheetsResponse, ...p])
        setCheckArray([])
    }

    //check button change for copy from modal
    const handleCopyChange = (data) => {
        CurrentWeek();
        const ar = checkArray?.some((d) => data === d);
        if (!ar) {
            const t = data?.seqArray?.some((d) => currentWeek?.some((r) => d.Date === r))
            const a = checkArray?.some((d) => d.seqArray?.some((f) => currentWeek?.some((r) => f.Date === r)))
            if (t && a) {
                setCheckArray([...checkArray, data])
            } else {
                setCheckArray([data])
            }
        }
        else {
            setCheckArray(checkArray.filter((d) => d !== data))
        }
    }

    //copy from modal
    const handleCopyDateChange = (direction) => {
        if (direction === "prev") {
            setCheckDate((prev) => moment(prev).subtract(isMobileView ? 1 : 7, "d").format("YYYY-MM-DD"));
        } else if (direction === "next") {
            setCheckDate((prev) => moment(prev).add(isMobileView ? 1 : 7, "d").format("YYYY-MM-DD"));
        } else {
            setCheckDate(moment()._d);
        }
    };

    // Get Project, Categories, Disiplines, Functions, Favourites, AllHolidays
    useEffect(() => {
        let subscribed = true;

        setIsPromiseLoading(true)
        setAllProjects([])
        setAllServiceCategories([])
        setAllServiceDisciplines([])
        setAllFunctions([])
        setAllFourites([])
        setAllHolidays([])

        Promise.all([getProjects(), getAllServiceCategories(), getServiceDesiplines(), getAllFunctions(), getHolidayByCountryAndYear(moment().format("YYYY"), currentUser?.Data?.PreferredCountryId,)]).then((response) => {
            if (subscribed) {
                const projectResponse = response[0]
                const serviceCategoryResponse = response[1]
                const serviceDisiplineResponse = response[2]
                const teamsResponse = response[3]
                const allHolidays = response[4]

                if (typeof projectResponse?.Data === "object" && projectResponse?.Data?.length >= 0) {
                    setAllProjects(projectResponse?.Data)
                } else {
                    handleError(projectResponse)
                }

                if (typeof serviceCategoryResponse?.Data === "object" && serviceCategoryResponse?.Data?.length >= 0) {
                    setAllServiceCategories(serviceCategoryResponse?.Data)
                } else {
                    handleError(serviceCategoryResponse)
                }

                if (typeof serviceDisiplineResponse?.Data === "object" && serviceDisiplineResponse?.Data?.length >= 0) {
                    setAllServiceDisciplines(serviceDisiplineResponse?.Data)
                } else {
                    handleError(serviceDisiplineResponse)
                }

                if (typeof teamsResponse?.Data === "object" && teamsResponse?.Data?.length >= 0) {
                    setAllFunctions(teamsResponse?.Data)
                } else {
                    handleError(teamsResponse)
                }

                if (typeof allHolidays?.Data === "object" && allHolidays?.Data?.length >= 0) {
                    setAllHolidays(allHolidays?.Data)
                } else {
                    handleError(allHolidays)
                }
            }
        }).catch((error) => {
            if (subscribed) {
                handleError(error)
            }
        }).finally(() => {
            if (subscribed) {
                setIsPromiseLoading(false);
            }
        })
    }, [])

    // Get AllTimesheets
    const GetAllTimesheets = async (email) => {
        setIsLoading(true)
        if (currentUser?.Data?.PersonalAssistantManager?.length > 0) {
            await getAllTimeSheets(weekDates[0], weekDates?.at(-1), email).then((response) => {
                if (typeof response?.Data?.TimeSheet === "object" && response?.Data?.TimeSheet?.length >= 0) {
                    const timeSheetsData = response?.Data?.TimeSheet?.map((timeSheet, timesheetIndex) => ({
                        ...timeSheet,
                        IndexId: timesheetIndex,
                        Date: moment(timeSheet?.Date).format("YYYY-MM-DD"),
                    }))
                    setAllTimesheetsResponse(timeSheetsData)
                    setAllTimesheets(timeSheetsData)
                    setCheckAllTimesheets(timeSheetsData)
                    checkSubmitedMonths(response?.Data?.SubmittedMonth)
                    setDefaultHolidaySelection({ FunctionId: response?.Data?.FunctionId, ServiceCategoriesId: response?.Data?.ServiceCategoriesId, ServiceDisciplinesId: response?.Data?.ServiceDisciplinesId })
                } else {
                    handleError(response)
                }
            }).catch((error) => {
                console.warn("error", error)
            }).finally(() => {
                setIsLoading(false)
            });
        }
    }

    // Get AllFavourites
    useEffect(() => {
        if (currentUser?.Data?.PersonalAssistantManager?.length > 0 && typeof userUpnPa === "number" && userUpnPa) {
            const userEmail = currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.UPN
            setIsLoading(true)
            getUserFavourite(userEmail).then((response) => {
                if (response?.Success) {
                    setAllFourites(response?.Data)
                } else {
                    setAllFourites([])
                }
            }).catch((error) => {
                console.warn("error", error);
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, [userUpnPa, currentUser?.Data?.PersonalAssistantManager])

    //for copy from modal
    useEffect(() => {
        setIsCheckSubmited(checkAllTimesheets.length > 0 ? checkAllTimesheets[0]?.IsSubmited : false)
    }, [checkAllTimesheets])

    //Timesheet getAll useEffect
    useEffect(() => {
        let subscribed = true;
        if (currentUser?.Data?.PersonalAssistantManager?.length > 0 && start && end && userEmail) {
            setIsLoading(true)
            getAllTimeSheets(start, end, userEmail).then((response) => {
                if (subscribed) {
                    if (typeof response?.Data?.TimeSheet === "object" && response?.Data?.TimeSheet?.length >= 0) {
                        const timeSheetsData = response?.Data?.TimeSheet?.map((timeSheet, timesheetIndex) => ({
                            ...timeSheet,
                            IndexId: timesheetIndex,
                            Date: moment(timeSheet?.Date).format("YYYY-MM-DD"),
                        }))
                        setAllTimesheetsResponse(timeSheetsData)
                        setAllTimesheets(timeSheetsData)
                        setCheckAllTimesheets(timeSheetsData)
                        checkSubmitedMonths(response?.Data?.SubmittedMonth)
                        setDefaultHolidaySelection({ FunctionId: response?.Data?.FunctionId, ServiceCategoriesId: response?.Data?.ServiceCategoriesId, ServiceDisciplinesId: response?.Data?.ServiceDisciplinesId })
                    } else {
                        handleError(response)
                    }
                }
            }).catch((error) => {
                if (subscribed) {
                    console.warn("error", error)
                }
            }).finally(() => {
                setIsLoading(false)
            });
        } else {
            if (subscribed) {
                setAllTimesheetsResponse([])
                setAllTimesheets([])
                setCheckAllTimesheets([])
            }
        }

        return () => {
            subscribed = false;
        }
    }, [start, end, userEmail])

    //Copy Timesheet getAll useEffect
    useEffect(() => {
        let subscribed = true;

        if (isCopyModalOpen && weekCheckDates[0] && weekCheckDates?.at(-1)) {
            setIsLoading(true)
            getAllTimeSheets(weekCheckDates[0], weekCheckDates?.at(-1), currentUser?.Data?.Email).then((response) => {
                if (subscribed) {
                    if (typeof response?.Data?.TimeSheet === "object" && response?.Data?.TimeSheet?.length >= 0) {
                        const timeSheetsData = response?.Data?.TimeSheet?.map((timeSheet, timesheetIndex) => ({
                            ...timeSheet,
                            IndexId: timesheetIndex,
                            Date: moment(timeSheet?.Date).format("YYYY-MM-DD"),
                        }))
                        setAllTimesheetsResponse(timeSheetsData)
                        setCheckAllTimesheets(timeSheetsData)
                    } else {
                        handleError(response)
                    }
                }
            }).catch((error) => {
                if (subscribed) {
                    console.warn("error", error)
                }
            }).finally(() => {
                setIsLoading(false)
            });
        } else {
            if (subscribed) {
                setAllTimesheetsResponse([])
                setCheckAllTimesheets([])
            }
        }

        return () => {
            subscribed = false;
        }
    }, [weekCheckDates[0], weekCheckDates?.at(-1)])

    // Hide & Show JQuery
    isMobileView && $(window).on("scroll", function () {
        if ($(this).scrollTop() > 50) {
            $(".back-to-top").fadeIn();
        } else {
            $(".back-to-top").fadeOut();
        }
    });

    return (
        <>
            {isLoading && <Loader />}
            {isPromiseLoading && <Loader />}

            <div className="new-timesheet">
                <main id="main" className="timesheet-main">
                    <div className="pagetitle mb" id="pagetitle">
                        <h1>Registration</h1>
                    </div>
                    <section className="section">
                        {/* Calendar Header */}
                        <div className="calendar-header mb-3 d-flex justify-content-between">
                            <div className="d-flex justify-content-between align-items-center">
                                {/* Calendar */}
                                <div className="calendar input-group d-flex align-items-center me-3">
                                    <button
                                        type="button"
                                        onClick={() => handleDateChange("prev")}
                                        className="input-group-prepend btn btn-sm btn-icon-left"
                                        title="Previous day"
                                    >
                                        <i className="fa-solid fa-chevron-left" />
                                    </button>

                                    <div className="px-3 py-2">
                                        <h6 className="mb-0 fw-medium text-dark text-center">
                                            {isMobileView
                                                ? moment(weekDates[0]).format("DD-MM-YYYY")
                                                : <>
                                                    {moment(calendarDate).startOf("isoWeek").format("MMM DD")}
                                                    {" - "}
                                                    {moment(calendarDate).startOf("isoWeek").format("MMM") !== moment(calendarDate).endOf("isoWeek").format("MMM")
                                                        ? moment(calendarDate).endOf("isoWeek").format("MMM DD")
                                                        : moment(calendarDate).endOf("isoWeek").format("DD")
                                                    }
                                                    {", "}
                                                    {moment(calendarDate).year()}
                                                    {", Week "}
                                                    {moment(calendarDate).isoWeek()}
                                                </>
                                            }
                                        </h6>
                                    </div>

                                    <button
                                        type="button"
                                        onClick={() => handleDateChange("next")}
                                        className="input-group-append btn btn-sm btn-icon-right"
                                        title="Next day"
                                    >
                                        <i className="fa-solid fa-chevron-right" />
                                    </button>
                                </div>

                                {/* Calendar Btn */}
                                <div className="calendar-btn me-3">
                                    <ReactDatePicker
                                        selected={moment(calendarDate)._d}
                                        dateFormat="dd/MM/yyyy"
                                        // renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled, }) => (
                                        //     <div className="d-flex align-item-center justify-content-between px-3">
                                        //         <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="btn p-0" style={{ minWidth: 0 }}>
                                        //             {prevMonthButtonDisabled ? "<" : <b>{"<"}</b>}
                                        //         </button>
                                        //         <b>{moment(date).format("MMMM")}</b>
                                        //         <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="btn p-0" style={{ minWidth: 0 }}>
                                        //             {nextMonthButtonDisabled ? ">" : <b>{">"}</b>}
                                        //         </button>
                                        //     </div>
                                        // )}
                                        onChange={(date) => date && setCalendarDate(moment(date).format("YYYY-MM-DD"))}
                                        dayClassName={(date) => calendarDate && date.toDateString() === moment(calendarDate)._d.toDateString() ? "app-theme-bg-color" : ""}
                                        customInput={<CustomDatePicker />}
                                        calendarStartDay={1}
                                        todayButton="Today"
                                    />
                                </div>

                                {/* PA Select */}
                                <div className="user-select me-3">
                                    <Select
                                        value={currentUser?.Data?.PersonalAssistantManager?.length > 0 ? ({
                                            label: currentUser?.Data?.PersonalAssistantManager?.find((u) => u?.UserId === userUpnPa)?.Name,
                                            value: userUpnPa
                                        }) : (
                                            {}
                                        )}
                                        onChange={(e) => setUserUpnPa(Number(e.value))}
                                        options={currentUser?.Data?.PersonalAssistantManager.length > 0
                                            ? currentUser?.Data?.PersonalAssistantManager?.map((user) => (
                                                ({ label: user?.Name, value: user?.UserId })
                                            ))
                                            : []}
                                        className="form-control p-0"
                                        placeholder="Select user"
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                marginTop: 2,
                                                border: "none",
                                                boxShadow: "none",
                                            }),
                                        }}
                                        components={{
                                            IndicatorSeparator: () => null,
                                        }}
                                    />
                                </div>

                                {/* Copy Btn */}
                                {!disableCondition2 &&
                                    <div className="copy-btn me-3">
                                        <button
                                            className="btn btn-primary btn"
                                            disabled={disableCondition1 || lockPeriodDisableCondition}
                                            onClick={() => {
                                                setCheckArray([])
                                                setCheckDate(calendarDate)
                                                setIsCopyModalOpen(true)
                                            }}
                                        >
                                            <i className="fa-solid fa-copy" />
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>

                        {/* Main Body */}
                        <div className="custom-table d-flex flex-wrap mt-5">
                            <div className="col-12 week-header text-center">
                                <span className="week-count">
                                    {"Week "}
                                    {moment(calendarDate).isoWeek()}
                                </span>
                            </div>
                            <table className="table tableCustom table-hover valignM tableHours">
                                <thead className="table-header">
                                    <tr>
                                        <th scope="col">&nbsp;</th>
                                        {/* <th scope="col">&nbsp;</th> */}
                                        <th scope="col">&nbsp;</th>
                                        <th scope="col">Tasks</th>
                                        {weekDates && weekDates?.length > 0 &&
                                            weekDates?.map((date, dateIndex) =>
                                                <th key={dateIndex} scope="col">
                                                    <span className="week">{moment(date).format("ddd")}</span>
                                                    <span className="day">{moment(date).format("MMM DD")}</span>
                                                </th>
                                            )}
                                    </tr>
                                </thead>
                                <tbody className="table-body">
                                    {/* */}
                                    {sequenceData && sequenceData?.length > 0 &&
                                        sequenceData?.map((data, index) =>
                                            <tr key={index} className="table-row">
                                                <td className="td-copy">
                                                    <button
                                                        className="btn form-control"
                                                        disabled={disableCondition1 || lockPeriodDisableCondition}
                                                        onClick={() => handleOnClickCopy(data?.seqArray)}
                                                    >
                                                        <i className="fa-solid fa-copy" />
                                                    </button>
                                                </td>
                                                <td className="td-delete">
                                                    <button
                                                        className="btn form-control"
                                                        disabled={data.hasOwnProperty("IsWeekSubmited")
                                                            ? (data?.IsWeekSubmited || lockPeriodDisableCondition)
                                                            : (disableCondition2 || lockPeriodDisableCondition)
                                                        }
                                                        onClick={() => handleOnClickDelete(data)}
                                                    >
                                                        <i className="fa-solid fa-trash-can" />
                                                    </button>
                                                </td>
                                                <td className="td-btn">
                                                    <button
                                                        type="button"
                                                        className="form-control btn btn-form-modal p-0"
                                                        disabled={data.hasOwnProperty("IsWeekSubmited")
                                                            ? (data?.IsWeekSubmited || lockPeriodDisableCondition)
                                                            : (disableCondition2 || lockPeriodDisableCondition)
                                                        }
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#editproject"
                                                        onClick={() => handleSetData(data)}
                                                    >
                                                        <div className="text-start px-3">
                                                            <div className="fw-medium project-display">
                                                                {allServiceCategories && allServiceCategories?.length > 0
                                                                    ? allServiceCategories?.filter((cate) => cate?.Id === data?.seqArray[0]?.ServiceCategoriesId)[0]?.ServiceCategoriesName
                                                                    ?? "--"
                                                                    : "--"
                                                                }{" - "}
                                                                {allServiceDisciplines && allServiceDisciplines?.length > 0
                                                                    ? allServiceDisciplines?.filter((disc) => disc?.Id === data?.seqArray[0]?.ServiceDisciplinesId)[0]?.ServiceDisciplinesName
                                                                    ?? "--"
                                                                    : "--"
                                                                }
                                                            </div>
                                                            <div className="text-black-50 comments-display">
                                                                {allProjects && allProjects?.length > 0
                                                                    ? allProjects?.filter((pro) => pro?.Id === data?.seqArray[0]?.ProjectId)[0]?.ProjectName
                                                                    ?? "--"
                                                                    : "--"
                                                                }{" - "}
                                                                {allFunctions && allFunctions?.length > 0
                                                                    ? allFunctions?.filter((func) => func?.Id === data?.seqArray[0]?.FunctionId)[0]?.Name
                                                                    ?? "--"
                                                                    : "--"
                                                                }
                                                            </div>
                                                        </div>
                                                    </button>
                                                </td>
                                                {data?.seqArray && data?.seqArray?.length > 0 &&
                                                    data?.seqArray?.map((timesheet, timesheetIndex) =>
                                                        <td key={timesheetIndex} className="td-time-input">
                                                            {!timesheet?.IsHoliday ? (
                                                                <>
                                                                    <span className="week">{moment(timesheet?.Date).format("MMM DD")}</span>
                                                                    <input
                                                                        className="form-control form2"
                                                                        value={timesheet?.Hour}
                                                                        id={`${timesheet?.Sequence}-${timesheet?.IsMobile ? 1 : 0}-${moment(timesheet?.Date).format("YYYY-MM-DD")}`}
                                                                        onChange={(e) => handleOnTimeInput(data, timesheet?.Date, e.target.value)}
                                                                        onBlur={(e) => handleAutoSaveOnTimeChange(timesheet, e.target.id)}
                                                                        disabled={timesheet?.IsSubmited || timesheetPeriodDisable(timesheet?.Date)}
                                                                        type="text"
                                                                        maxLength="6"
                                                                        autoComplete="off"
                                                                    />
                                                                    {timesheet?.Id !== 0 && (
                                                                        <>
                                                                            <a
                                                                                className={`hour-notes ${timesheet?.IsMobile}-${timesheet?.Sequence}-${moment(timesheet?.Date).format("YYYY-MM-DD")}`}
                                                                                onClick={() => {
                                                                                    setInputSeqData(timesheet)
                                                                                    setInputNotes(timesheet?.Notes ?? "")
                                                                                }}
                                                                                data-bs-toggle={timesheet?.IsSubmited ? "" : "modal"}
                                                                                data-bs-target={timesheet?.IsSubmited ? "" : "#notesmodal"}
                                                                            >
                                                                                <i
                                                                                    className="fa-regular fa-message"
                                                                                    disabled={timesheet?.IsSubmited || lockPeriodDisableCondition}
                                                                                />
                                                                            </a>
                                                                            <Tooltip anchorSelect={`.${timesheet?.IsMobile}-${timesheet?.Sequence}-${moment(timesheet?.Date).format("YYYY-MM-DD")}`} place="top">
                                                                                {timesheet?.Notes}
                                                                            </Tooltip>
                                                                        </>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <div className="holiday-div">
                                                                    <a onClick={() => handleEnableHoliday(timesheet)} className={`${timesheet?.IsSubmited ? "holiday-disable" : "holiday"} ${timesheet?.IsMobile}-${timesheet?.Sequence}-${moment(timesheet?.Date).format("YYYY-MM-DD")}`}>
                                                                        <span className="week">{moment(timesheet?.Date).format("MMM DD")}</span>
                                                                        <input
                                                                            className="form-control form2 border-success bg-success-50 text-success holiday-input-disable"
                                                                            defaultValue={"H"}
                                                                            id={`${timesheet?.Sequence}-${timesheet?.IsMobile ? 1 : 0}-${moment(timesheet?.Date).format("YYYY-MM-DD")}`}
                                                                            type="text" maxLength="6" autoComplete="off"
                                                                        />
                                                                    </a>
                                                                    <Tooltip anchorSelect={`.${timesheet?.IsMobile}-${timesheet?.Sequence}-${moment(timesheet?.Date).format("YYYY-MM-DD")}`} place="top">
                                                                        {timesheet?.Holiday}
                                                                    </Tooltip>
                                                                </div>
                                                            )}
                                                        </td>
                                                    )}
                                            </tr>
                                        )}
                                    {/* */}
                                    {/* Time footer */}
                                    <tr className="table-row">
                                        <td className="add-new-row" colSpan="3">
                                            <button
                                                type="submit"
                                                className="btn btn-link add-row"
                                                disabled={disableCondition1 || lockPeriodDisableCondition}
                                                onClick={() => handleOnClickAddRow()}
                                            >
                                                <i className="fa-solid fa-plus" />&nbsp;Add new row
                                            </button>
                                        </td>
                                        {totalTimeByDate && totalTimeByDate?.length > 0 &&
                                            totalTimeByDate?.map((date, dateIndex) =>
                                                <td key={dateIndex}>
                                                    {!date?.TotalTime ? "0.00" : date?.TotalTime?.toFixed(2)}
                                                </td>
                                            )
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                </main>

                <div className="submit-footer d-flex justify-content-between align-itmes-center" id="submit-footer">
                    <div className="total-hours d-inline-flex justify-content-start align-itmes-center">
                        <span key={totalOfTotalTime} className="me-3 line-sm">{totalOfTotalTime?.toFixed(2)}</span>
                        <div>
                            <div className="text-md">Total Hours</div>
                            <div className="text-sm txt-gray">
                                {isMobileView
                                    ? moment(weekDates[0]).format("DD-MM-YYYY")
                                    : <>
                                        {"Week "}
                                        {moment(calendarDate).isoWeek()}
                                        {", "}
                                        {moment(calendarDate).year()}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="submit text-end d-flex gap-3">
                        <div className="d-flex justify-content-center align-items-center p-2 text-secondary">
                            {isAutoSaveing ? "Auto saving..." : ""}
                        </div>
                        <button
                            className="submit-btn btn btn-primary btn-lg btn"
                            onClick={() => saveWeekData()}
                            disabled={disableCondition1 || lockPeriodDisableCondition}
                        >
                            Save
                        </button>
                        {currentDateInRange()?.submitShow && !disableCondition3 &&
                            <button
                                className="submit-btn btn btn-primary btn-lg btn"
                                onClick={() => submitWeekData()}
                                disabled={disableCondition3 || lockPeriodDisableCondition}
                            >
                                Submit {`(${currentDateInRange()?.submitMonth})`}
                            </button>
                        }
                    </div>
                </div>

                {isMobileView && (
                    <div onClick={() => scrollToDiv()} className="back-to-top justify-content-center align-items-center">
                        <i className="fa-solid fa-arrow-up" />
                    </div>
                )}

                {/* Modal */}
                <div className="modal fade" id="editproject">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Timesheet</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" />
                            </div>
                            <div className="modal-body mb-4">
                                <form className="row g-3">
                                    {/* Function */}
                                    <div className="col-12 m-0">
                                        <label htmlFor="inputName4" className="form-label">Function</label>
                                        <Select
                                            value={allFunctions?.length > 0 && inputFunction && allFunctions?.find((func) => func?.Id === inputFunction)?.Id ? ({
                                                value: inputFunction,
                                                label: allFunctions?.find((func) => func?.Id === inputFunction)?.Name
                                            }) : ([])}
                                            onChange={(e) => {
                                                setInputFunction(Number(e.value))
                                                setInputServiceCategory(0)
                                                setInputServiceDiscipline(0)
                                            }}
                                            options={allFunctions?.length > 0 &&
                                                allFunctions.filter((d) => d?.IsActive).map((func) => (
                                                    ({ value: func?.Id, label: func?.Name })
                                                ))
                                            }
                                            className="form-control p-1 mb-3"
                                            placeholder="Select funtion"
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    marginTop: 2,
                                                    border: "none",
                                                    boxShadow: "none",
                                                }),
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                    </div>

                                    {/* Service category */}
                                    <div className="col-12 m-0">
                                        <label htmlFor="inputName4" className="form-label">Service category</label>
                                        <Select
                                            value={allServiceCategories?.length > 0 && inputServiceCategory && allServiceCategories?.find((cate) => cate?.Id === inputServiceCategory)?.Id ? ({
                                                value: inputServiceCategory,
                                                label: allServiceCategories?.find((cate) => cate?.Id === inputServiceCategory)?.ServiceCategoriesName
                                            }) : ([])}
                                            onChange={(e) => {
                                                setInputServiceCategory(Number(e.value))
                                                setInputServiceDiscipline(0)
                                            }}
                                            options={allServiceCategories?.length > 0 && (
                                                (inputFunction
                                                    ? allServiceCategories?.filter((cate) => cate?.FunctionId === inputFunction)
                                                    : allServiceCategories
                                                )?.filter((d) => d?.IsActive)?.map((category) => (
                                                    ({ value: category?.Id, label: category?.ServiceCategoriesName })
                                                ))
                                            )}
                                            className="form-control p-1 mb-3"
                                            placeholder="Select category"
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    marginTop: 2,
                                                    border: "none",
                                                    boxShadow: "none",
                                                }),
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                    </div>

                                    {/* Service discipline */}
                                    <div className="col-12 m-0">
                                        <label htmlFor="inputName4" className="form-label">Service discipline</label>
                                        <Select
                                            value={allServiceDisciplines?.length > 0 && inputServiceDiscipline && allServiceDisciplines?.find((discipline) => discipline?.Id === inputServiceDiscipline)?.Id ? ({
                                                value: inputServiceDiscipline,
                                                label: allServiceDisciplines?.find((discipline) => discipline?.Id === inputServiceDiscipline)?.ServiceDisciplinesName
                                            }) : ([])}
                                            onChange={(e) => setInputServiceDiscipline(Number(e.value))}
                                            options={allServiceDisciplines.length > 0 && (
                                                (inputServiceCategory
                                                    ? allServiceDisciplines?.filter((discipline) => discipline?.ServiceCategoriesId === inputServiceCategory)
                                                    : allServiceDisciplines
                                                )?.filter((d) => d?.IsActive)?.map((discipline) => (
                                                    ({ value: discipline?.Id, label: discipline?.ServiceDisciplinesName })
                                                ))
                                            )}
                                            className="form-control p-1 mb-3"
                                            placeholder="Select discipline"
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    marginTop: 2,
                                                    border: "none",
                                                    boxShadow: "none",
                                                }),
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                    </div>

                                    {/* Project */}
                                    <div className="col-12 m-0">
                                        <label htmlFor="inputName4" className="form-label">Project</label>
                                        <Select
                                            value={allProjects?.length > 0 && inputProject && allProjects?.find((pro) => pro?.Id === inputProject)?.Id ? ({
                                                value: inputProject,
                                                label: allProjects?.find((pro) => pro?.Id === inputProject)?.ProjectName
                                            }) : ([])}
                                            onChange={(e) => setInputProject(Number(e.value))}
                                            options={allProjects?.length > 0 ? (
                                                allProjects?.filter((v) => v.IsActive === true)?.map((pro) => ({
                                                    label: pro?.ProjectName,
                                                    value: pro?.Id
                                                }))
                                            ) : (
                                                []
                                            )}
                                            className="form-control p-1 mb-3"
                                            placeholder="Select project"
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    marginTop: 2,
                                                    border: "none",
                                                    boxShadow: "none",
                                                }),
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                    </div>

                                    {/* Comment */}
                                    <div className="col-12 m-0">
                                        <label htmlFor="comments" className="form-label">Comment</label>
                                        <textarea value={inputComment} onChange={((e) => setInputComment(e.target.value))} className="form-control" rows={4} />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    data-bs-dismiss="modal"
                                    className="cancel-btn btn btn-transparent btn-lg m-0 me-2"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    data-bs-dismiss={`${inputFunction !== 0 &&
                                        inputServiceCategory !== 0 &&
                                        inputServiceDiscipline !== 0 &&
                                        (allServiceCategories?.filter((cate) => cate?.Id === inputServiceCategory)[0]?.ProjectSelection ? inputProject !== 0 : true)
                                        ? "modal"
                                        : ""}`}
                                    className="submit-btn btn btn-primary btn-lg m-0"
                                    onClick={() => handleSaveData()}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Notes Modal */}
                <div className="modal fade" id="notesmodal">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Notes</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" />
                            </div>
                            <div className="modal-body mb-4">
                                <form className="row g-3">
                                    {/* Comment */}
                                    <div className="col-12 m-0">
                                        <label htmlFor="comments" className="form-label">Comment</label>
                                        <textarea value={inputNotes} onChange={((e) => setInputNotes(e.target.value))} className="form-control" rows={4} />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    data-bs-dismiss="modal"
                                    className="cancel-btn btn btn-transparent btn-lg m-0 me-2"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    data-bs-dismiss="modal"
                                    className="submit-btn btn btn-primary btn-lg m-0"
                                    onClick={() => handleSetNotes()}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* check modal */}
                <div className={`modal editinfo fade copymodal ${isCopyModalOpen ? "show d-block" : "d-none"}`} id="checktimesheet" tabIndex={-1} >
                    <div className="modal-dialog p-0 d-flex justify-content-center align-items-center">
                        <div className="modal-content modal-custom-content">
                            <div className="modal-custom-header">
                                <div>
                                    <h2 className="">Copy Timesheet</h2>
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setCheckArray([])
                                            setIsCopyModalOpen(false)
                                        }}
                                        aria-label="Close"
                                    />
                                </div>
                            </div>
                            <div className="modal-body mb-4 mt-0" >
                                <div>
                                    {/* Calendar Header */}
                                    <div className="calendar-header mb-3 d-flex justify-content-between align-items-center row">
                                        <div className="d-flex justify-content-center col gap-2">
                                            {/* Calendar */}
                                            <div className="calendar col-8 d-flex align-items-center border-0 p-0 me-3  ">
                                                <button
                                                    type="button"
                                                    onClick={() => handleCopyDateChange("prev")}
                                                    className="input-group-prepend btn btn-sm btn-icon-left"
                                                    title="Previous day"
                                                >
                                                    <i className="fa-solid fa-chevron-left" />
                                                </button>

                                                <div className="p-0">
                                                    <h6 className="m-0 fw-medium text-dark text-center py-2 px-1">
                                                        {isMobileView
                                                            ? moment(weekCheckDates[0]).format("DD-MM-YYYY")
                                                            : <>
                                                                {moment(checkDate).startOf("isoWeek").format("MMM DD")}
                                                                {" - "}
                                                                {moment(checkDate).startOf("isoWeek").format("MMM") !== moment(checkDate).endOf("isoWeek").format("MMM")
                                                                    ? moment(checkDate).endOf("isoWeek").format("MMM DD")
                                                                    : moment(checkDate).endOf("isoWeek").format("DD")
                                                                }
                                                                {", "}
                                                                {moment(checkDate).year()}
                                                                {", Week "}
                                                                {moment(checkDate).isoWeek()}
                                                            </>
                                                        }
                                                    </h6>
                                                </div>

                                                <button
                                                    type="button"
                                                    onClick={() => handleCopyDateChange("next")}
                                                    className="input-group-append btn btn-sm btn-icon-right"
                                                    title="Next day"
                                                >
                                                    <i className="fa-solid fa-chevron-right" />
                                                </button>
                                            </div>

                                            <div className="col">
                                                {/* Calendar Btn */}
                                                <div className="col">
                                                    <ReactDatePicker
                                                        selected={moment(checkDate)._d}
                                                        dateFormat="dd/MM/yyyy"
                                                        // renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled, }) => (
                                                        //     <div className="d-flex align-item-center justify-content-between px-3">
                                                        //         <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="btn p-0" style={{ minWidth: 0 }}>
                                                        //             {prevMonthButtonDisabled ? "<" : <b>{"<"}</b>}
                                                        //         </button>
                                                        //         <b>{moment(date).format("MMMM")}</b>
                                                        //         <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="btn p-0" style={{ minWidth: 0 }}>
                                                        //             {nextMonthButtonDisabled ? ">" : <b>{">"}</b>}
                                                        //         </button>
                                                        //     </div>
                                                        // )}
                                                        onChange={(date) => date && setCheckDate(moment(date).format("YYYY-MM-DD"))}
                                                        dayClassName={(date) => checkDate && date.toDateString() === moment(checkDate)._d.toDateString() ? "app-theme-bg-color" : ""}
                                                        customInput={<CustomDatePicker />}
                                                        calendarStartDay={1}
                                                        todayButton="Today"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Main Body */}
                                    <div className="custom-table d-flex flex-wrap mt-5 style-4">
                                        <div className="col-12 week-header text-center">
                                            <span className="week-count">
                                                {"Week "}
                                                {moment(checkDate).isoWeek()}</span>
                                        </div>
                                        <table className="table tableCustom table-hover valignM tableHours ">
                                            <thead className="table-header">
                                                <tr align="center">
                                                    <th scope="col">&nbsp;</th>
                                                    <th scope="col">Tasks</th>
                                                    {weekCheckDates && weekCheckDates?.length > 0 &&
                                                        weekCheckDates?.map((date, dateIndex) =>
                                                            <th key={dateIndex} scope="col">
                                                                <span className="week row">{moment(date).format("ddd")}</span>
                                                                <span className="day row fw-light ">{moment(date).format("MMM DD")}</span>
                                                            </th>
                                                        )}
                                                </tr>
                                            </thead>
                                            <tbody className="table-body">
                                                {/* */}
                                                {sequenceCheckData && sequenceCheckData?.length > 0 &&
                                                    sequenceCheckData?.map((data, index) =>
                                                        <tr key={index} className="table-row">
                                                            <td className="td-copy col-2">
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <input
                                                                        className="form-check-input m-0"
                                                                        type="checkbox"
                                                                        id="flexSwitchCheckDisabled"
                                                                        checked={checkArray?.some((d) => d === data)}
                                                                        onChange={() => handleCopyChange(data)}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className="td-btn">
                                                                <div className="form-control btn-form-modal p-0">
                                                                    <div className="text-start px-2 py-1 customTd">
                                                                        <div className="fw-medium project-display">
                                                                            {allServiceCategories && allServiceCategories?.length > 0
                                                                                ? allServiceCategories?.filter((cate) => cate?.Id === data?.seqArray[0]?.ServiceCategoriesId)[0]?.ServiceCategoriesName
                                                                                ?? "--"
                                                                                : "--"
                                                                            }{" - "}
                                                                            {allServiceDisciplines && allServiceDisciplines?.length > 0
                                                                                ? allServiceDisciplines?.filter((disc) => disc?.Id === data?.seqArray[0]?.ServiceDisciplinesId)[0]?.ServiceDisciplinesName
                                                                                ?? "--"
                                                                                : "--"
                                                                            }
                                                                        </div>
                                                                        <div className="text-black-50 comments-display">
                                                                            {allProjects && allProjects?.length > 0
                                                                                ? allProjects?.filter((pro) => pro?.Id === data?.seqArray[0]?.ProjectId)[0]?.ProjectName
                                                                                ?? "--"
                                                                                : "--"
                                                                            }{" - "}
                                                                            {allFunctions && allFunctions?.length > 0
                                                                                ? allFunctions?.filter((func) => func?.Id === data?.seqArray[0]?.FunctionId)[0]?.Name
                                                                                ?? "--"
                                                                                : "--"
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            {data?.seqArray && data?.seqArray?.length > 0 &&
                                                                data?.seqArray?.map((timesheet, timesheetIndex) =>
                                                                    <td key={timesheetIndex} className="td-time-input">
                                                                        <span className="week">{moment(timesheet?.Date).format("MMM DD")}</span>
                                                                        <div className="hour-div d-flex justify-content-center align-items-center">
                                                                            {timesheet?.Hour}
                                                                        </div>
                                                                    </td>
                                                                )}
                                                        </tr>
                                                    )}

                                                {/* Time footer */}
                                                <tr className="table-row" align="center">
                                                    <td colSpan="2">
                                                    </td>
                                                    {totalTimeByDate && totalTimeByDate?.length > 0 &&
                                                        totalTimeByDate?.map((date, dateIndex) =>
                                                            <td key={dateIndex} >
                                                                {!date?.TotalTime ? "0.00" : date?.TotalTime?.toFixed(2)}
                                                            </td>
                                                        )
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn cancel-btn btn-transparent btn-lg me-2"
                                    onClick={() => {
                                        setCheckArray([])
                                        setIsCopyModalOpen(false)
                                    }}
                                    data-bs-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleCheckCopy()
                                        setIsCopyModalOpen(false)
                                    }}
                                    className="btn copy-btn btn-primary btn-lg"
                                    data-bs-dismiss="modal"
                                >
                                    Copy
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Timesheet