import React, { useEffect, useState } from 'react'
import "./Preference.scss"
import Select from 'react-select'
import swal from 'sweetalert'
import { createUpdateFavourite, deleteFavourite, getAllFunctions, getAllServiceCategories, getPreferences, getProjects, getServiceDesiplines, getUserFavourite } from '../../services/ApiServices'
import { handleError } from '../../const'
import Loader from '../../components/Loader/Loader'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { error } from 'jquery'

const Preference = () => {
    const currentUser = useSelector((state) => state.MSALAuth.currentUser)

    const [isLoading, setIsLoading] = useState(false)

    // Preference
    const [inputPreFunction, setInputPreFunction] = useState(0)
    const [inputPreServiceCategory, setInputPreServiceCategory] = useState(0)
    const [inputPreServiceDiscipline, setInputPreServiceDiscipline] = useState(0)
    const [inputPreProject, setInputPreProject] = useState(0)
    const [inputPreComment, setInputPreComment] = useState("")

    // Favourite
    const [inputFavFunction, setInputFavFunction] = useState(0)
    const [inputFavServiceCategory, setInputFavServiceCategory] = useState(0)
    const [inputFavServiceDiscipline, setInputFavServiceDiscipline] = useState(0)
    const [inputFavProject, setInputFavProject] = useState(0)
    const [inputFavSortOrder, setInputFavSortOrder] = useState(0)
    const [favouriteId, setFaveriteId] = useState(0);
    // const [inputFavComment, setInputFavComment] = useState("")

    //Required validation
    const [functionError, setFunctionError] = useState(false);
    const [serviceCategoryError, setServiceCategoryError] = useState(false);
    const [serviceDisciplineError, setServiceDisciplineError] = useState(false);
    const [projectError, setProjectError] = useState(false);
    const [sortOrderError, setSortOrderError] = useState(false);

    //modal state
    const [modalFavFunctionInput, setModalFavFunctionInput] = useState(0)
    const [modalFavServiceCategoryInput, setModalFavServiceCategoryInput] = useState(0)
    const [modalFavServiceDiscplineInput, setModalFavServiceDiscplineInput] = useState(0)
    const [modalFavProjectInput, setModalFavProjectInput] = useState(0)
    const [modalFavSortOrder, setModalFavSortOrder] = useState(0)

    //modal state validation
    const [functionModalError, setFunctionModalError] = useState(false);
    const [serviceCategoryModalError, setServiceCategoryModalError] = useState(false);
    const [serviceDisciplineModalError, setServiceDisciplineModalError] = useState(false);
    const [projectModalError, setProjectModalError] = useState(false);

    const [allFunctions, setAllFunctions] = useState([])
    const [allProjects, setAllProjects] = useState([])
    const [allServiceCategories, setAllServiceCategories] = useState([])
    const [allServiceDisciplines, setAllServiceDisciplines] = useState([])

    const [allUserFavourite, setAllUserFavourite] = useState([]);

    const setFavouriteData = (data) => {
        setFaveriteId(data?.Id)
        setModalFavFunctionInput(data?.FunctionId ?? 0)
        setModalFavServiceCategoryInput(data?.ServiceCategoriesId ?? 0)
        setModalFavServiceDiscplineInput(data?.ServiceDisciplinesId ?? 0)
        setModalFavProjectInput(data?.ProjectId ?? 0)
        setModalFavSortOrder(data?.SortOrder)
    }

    const handleSave = async () => {
        const ProjectSelection = allServiceCategories.find((sc) => sc.Id === inputFavServiceCategory)?.ProjectSelection;

        if (inputFavFunction === 0) {
            setFunctionError(true)
        } else {
            setFunctionError(false)
            if (inputFavServiceCategory === 0) {
                setServiceCategoryError(true)
            } else {
                setServiceCategoryError(false)
                if (inputFavServiceDiscipline === 0) {
                    setServiceDisciplineError(true)
                } else {
                    setServiceDisciplineError(false)
                    afterValidation(ProjectSelection)
                }
            }
        }
    }

    const handleUpdate = async () => {
        const ProjectSelection = allServiceCategories.find((sc) => sc.Id === modalFavServiceCategoryInput)?.ProjectSelection;

        if (modalFavFunctionInput === 0) {
            setFunctionModalError(true)
        } else {
            setFunctionModalError(false)
            if ((modalFavFunctionInput ? allServiceCategories?.filter((cate) => cate?.FunctionId === modalFavFunctionInput)
                : allServiceCategories
            )?.length > 0 && modalFavServiceCategoryInput === 0) {
                setServiceCategoryModalError(true)
            } else {
                setServiceCategoryModalError(false)
                if ((modalFavServiceCategoryInput
                    ? allServiceDisciplines?.filter((discipline) => discipline?.ServiceCategoriesId === modalFavServiceCategoryInput)
                    : allServiceDisciplines
                )?.length > 0 && modalFavServiceCategoryInput === 0) {
                    setServiceDisciplineModalError(true)
                } else {
                    setServiceDisciplineModalError(false)
                    afterValidation(ProjectSelection)
                }
            }
        }
    }

    const afterValidation = (ProjectSelection) => {
        if (favouriteId !== 0) {
            if (ProjectSelection) {
                if (modalFavProjectInput !== 0) {
                    setProjectModalError(false)
                    saveUpdateFav()
                } else {
                    // toast.error("Please Select Project")
                    setProjectModalError(true)
                    setIsLoading(false)
                }
            } else {
                saveUpdateFav()
            }
        } else {
            if (ProjectSelection) {
                if (inputFavProject !== 0) {
                    setProjectError(false)
                    saveUpdateFav()
                } else {
                    // toast.error("Please Select Project")
                    setProjectError(true)
                    setIsLoading(false)
                }
            } else {
                saveUpdateFav()
            }
        }
    }

    const saveUpdateFav = async () => {
        setIsLoading(true)

        let payload = {}
        if (favouriteId !== 0) {
            payload = {
                Id: favouriteId,
                FunctionId: modalFavFunctionInput,
                ServiceCategoriesId: modalFavServiceCategoryInput,
                ServiceDisciplinesId: modalFavServiceDiscplineInput,
                ProjectId: modalFavProjectInput,
                SortOrder: modalFavSortOrder > 0 && modalFavSortOrder || 0,
            }
        } else {
            payload = {
                FunctionId: inputFavFunction,
                ServiceCategoriesId: inputFavServiceCategory,
                ServiceDisciplinesId: inputFavServiceDiscipline,
                ProjectId: inputFavProject,
                SortOrder: inputFavSortOrder > 0 && inputFavSortOrder || 0,
            }
        }

        await createUpdateFavourite(payload).then((response) => {
            if (response?.Success) {
                toast.success(response.Message)
                GetUserFavourite()
                setFaveriteId(0)
                setInputFavFunction(0)
                setInputFavServiceCategory(0)
                setInputFavServiceDiscipline(0)
                setInputFavProject(0)
                setInputFavSortOrder(0)
                setModalFavFunctionInput(0)
                setModalFavServiceCategoryInput(0)
                setModalFavServiceDiscplineInput(0)
                setModalFavProjectInput(0)
                setModalFavSortOrder(0)
            } else {
                toast.error(response.Message)
            }
        }).catch((error) => {
            console.warn("error", error);
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const GetUserFavourite = async () => {
        setIsLoading(true)
        await getUserFavourite(currentUser?.Data?.Email).then((response) => {
            if (response?.Success) {
                setAllUserFavourite(response?.Data)
            } else {
                setAllUserFavourite([])
            }
        }).catch((error) => {
            console.warn("error", error);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const handleDeleteFavourite = (Id) => {
        const deleteData = async () => {
            setIsLoading(true);

            await deleteFavourite(Id).then((response) => {
                toast.success(response?.Message)
                GetUserFavourite();
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setIsLoading(false);
            })
        };

        swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover!',
            icon: 'warning',
            buttons: ['Cancel', 'Yes, delete it!'],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteData();
            } else {
                setIsLoading(false);
            }
        });
    };

    useEffect(() => {
        GetUserFavourite()
    }, []);

    // Get Functions, Categories, Disiplines, Projects,
    useEffect(() => {
        let subscribed = true;

        setIsLoading(true)
        setAllFunctions([])
        setAllServiceCategories([])
        setAllServiceDisciplines([])
        setAllProjects([])

        Promise.all([getProjects(), getAllServiceCategories(), getServiceDesiplines(), getAllFunctions()]).then((response) => {
            if (subscribed) {
                const projectResponse = response[0]
                const serviceCategoryResponse = response[1]
                const serviceDisiplineResponse = response[2]
                const teamsResponse = response[3]

                if (typeof projectResponse?.Data === "object" && projectResponse?.Data?.length >= 0) {
                    setAllProjects(projectResponse?.Data)
                } else {
                    handleError(projectResponse)
                }

                if (typeof serviceCategoryResponse?.Data === "object" && serviceCategoryResponse?.Data?.length >= 0) {
                    setAllServiceCategories(serviceCategoryResponse?.Data)
                } else {
                    handleError(serviceCategoryResponse)
                }

                if (typeof serviceDisiplineResponse?.Data === "object" && serviceDisiplineResponse?.Data?.length >= 0) {
                    setAllServiceDisciplines(serviceDisiplineResponse?.Data)
                } else {
                    handleError(serviceDisiplineResponse)
                }

                if (typeof teamsResponse?.Data === "object" && teamsResponse?.Data?.length >= 0) {
                    setAllFunctions(teamsResponse?.Data)
                } else {
                    handleError(teamsResponse)
                }
            }
        }).catch((error) => {
            if (subscribed) {
                handleError(error)
            }
        }).finally(() => {
            if (subscribed) {
                setIsLoading(false);
            }
        })

        return () => {
            subscribed = false
            setIsLoading(false)
        }
    }, [])

    // useEffect(() => {
    //     GetPreference()
    // }, [])

    return (
        <>
            {isLoading && <Loader />}
            <main id="main" className="preference-main">
                <section className="section">
                    <div className="top-inputs">
                        <div className="col">
                            {/* < ====================================== Favourite ====================================== > */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div className="pagetitle mb">
                                    <h1>Favourite</h1>
                                    <h5>Change your favourites</h5>
                                </div>
                                <div className="table tableCustom table-hover" >

                                    <div>
                                        <div className='row'>

                                            {/* Function  */}
                                            <div className='col-6 d-flex justify-content-between '>
                                                <div className="title d-flex align-items-center ">
                                                    <span >Function</span>
                                                </div>
                                                <div className="title d-flex align-items-center w-50">
                                                    <Select
                                                        value={allFunctions?.length > 0 && inputFavFunction ? ({
                                                            value: inputFavFunction,
                                                            label: allFunctions?.find((func) => func?.Id === inputFavFunction)?.Name,
                                                        }) : (
                                                            { value: 0, label: "Select function" }
                                                        )}
                                                        onChange={(e) => {
                                                            setInputFavFunction(Number(e.value))
                                                            setInputFavServiceCategory(0)
                                                            setInputFavServiceDiscipline(0)
                                                        }}
                                                        options={
                                                            allFunctions?.length > 0
                                                                ? [
                                                                    { value: 0, label: "Select function" },
                                                                    ...allFunctions.filter((d) => d?.IsActive)?.map((func) => ({
                                                                        value: func?.Id,
                                                                        label: func?.Name,
                                                                    }))
                                                                ]
                                                                : []
                                                        }
                                                        className="form-control p-1 "
                                                        placeholder="Select function"
                                                        styles={{
                                                            control: (baseStyles) => ({
                                                                ...baseStyles,
                                                                marginTop: 2,
                                                                border: "none",
                                                                boxShadow: "none",
                                                            }),
                                                        }}
                                                        components={{
                                                            IndicatorSeparator: () => null,
                                                        }}
                                                    />
                                                    {functionError && <p className=' text-danger  ps-2 small m-0'>Please select function!</p>}
                                                </div>
                                            </div>

                                            {/* Service Discpline  */}
                                            <div className='col-6  d-flex justify-content-between '>
                                                <div className="title d-flex align-items-center">
                                                    <span>Service Discpline</span>
                                                </div>
                                                <div className="title d-flex align-items-center w-50">
                                                    <Select
                                                        value={allServiceDisciplines?.length > 0 && inputFavServiceDiscipline && allServiceDisciplines?.find((discipline) => discipline?.Id === inputFavServiceDiscipline)?.Id ? ({
                                                            value: inputFavServiceDiscipline,
                                                            label: allServiceDisciplines?.find((discipline) => discipline?.Id === inputFavServiceDiscipline)?.ServiceDisciplinesName
                                                        }) : (
                                                            { value: 0, label: "Select service discipline" }
                                                        )}
                                                        onChange={(e) => setInputFavServiceDiscipline(Number(e.value))}
                                                        options={allServiceDisciplines.length > 0 && (
                                                            (inputFavServiceCategory
                                                                ? allServiceDisciplines?.filter((discipline) => discipline?.ServiceCategoriesId === inputFavServiceCategory)
                                                                : allServiceDisciplines
                                                            )?.filter((d) => d?.IsActive)?.map((discipline) => (
                                                                ({ value: discipline?.Id, label: discipline?.ServiceDisciplinesName })
                                                            ))
                                                        )}
                                                        className="form-control p-1"
                                                        placeholder="Select service discipline"
                                                        styles={{
                                                            control: (baseStyles) => ({
                                                                ...baseStyles,
                                                                marginTop: 2,
                                                                border: "none",
                                                                boxShadow: "none",
                                                            }),
                                                        }}
                                                        components={{
                                                            IndicatorSeparator: () => null,
                                                        }}
                                                    />
                                                    {serviceDisciplineError && <p className='small text-danger ps-2 '>Please select Service Discpline!</p>}

                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>

                                            {/* Service Category  */}
                                            <div className='col-6 d-flex justify-content-between '>
                                                <div className="title d-flex align-items-center">
                                                    <span>Service Category</span>
                                                </div>
                                                <div className="title d-flex align-items-center w-50">
                                                    <Select
                                                        value={allServiceCategories?.length > 0 && inputFavServiceCategory && allServiceCategories?.find((cate) => cate?.Id === inputFavServiceCategory)?.Id ? ({
                                                            value: inputFavServiceCategory,
                                                            label: allServiceCategories?.find((cat) => cat?.Id === inputFavServiceCategory)?.ServiceCategoriesName
                                                        }) : (
                                                            { value: 0, label: "Select service category" }
                                                        )}
                                                        onChange={(e) => {
                                                            setInputFavServiceCategory(Number(e.value))
                                                            setInputFavServiceDiscipline(0)
                                                        }}
                                                        options={allServiceCategories?.length > 0 &&
                                                            (inputFavFunction
                                                                ? allServiceCategories?.filter((cate) => cate?.FunctionId === inputFavFunction)
                                                                : allServiceCategories
                                                            )?.filter((d) => d?.IsActive)?.map((cat) => ({
                                                                value: cat?.Id,
                                                                label: cat?.ServiceCategoriesName
                                                            }))
                                                        }
                                                        className="form-control p-1"
                                                        placeholder="Select service category"
                                                        styles={{
                                                            control: (baseStyles) => ({
                                                                ...baseStyles,
                                                                marginTop: 2,
                                                                border: "none",
                                                                boxShadow: "none",
                                                            }),
                                                        }}
                                                        components={{
                                                            IndicatorSeparator: () => null,
                                                        }}
                                                    />
                                                    {serviceCategoryError && <p className='small text-danger ps-2'>Please select Service Category!</p>}
                                                </div>
                                            </div>

                                            {/* Project  */}
                                            <div className='col-6 d-flex justify-content-between '>
                                                <div className="title d-flex align-items-center">
                                                    <span>Project</span>
                                                </div>
                                                <div className="title d-flex align-items-center w-50">
                                                    <Select
                                                        value={allProjects?.length > 0 && inputFavProject && allProjects?.find((pro) => pro?.Id === inputFavProject)?.Id ? ({
                                                            value: inputFavProject,
                                                            label: allProjects?.find((pro) => pro?.Id === inputFavProject)?.ProjectName
                                                        }) : (
                                                            { value: 0, label: "Select project" }
                                                        )}
                                                        onChange={(e) => setInputFavProject(Number(e.value))}
                                                        options={allProjects?.length > 0 && [
                                                            { value: 0, label: "Select project" },
                                                            ...allProjects?.filter((d) => d?.IsActive)?.map((pro) => (
                                                                ({ value: pro?.Id, label: pro?.ProjectName })
                                                            ))
                                                        ]
                                                        }
                                                        className="form-control p-1"
                                                        placeholder="Select"
                                                        styles={{
                                                            control: (baseStyles) => ({
                                                                ...baseStyles,
                                                                marginTop: 2,
                                                                border: "none",
                                                                boxShadow: "none",
                                                            }),
                                                        }}
                                                        components={{
                                                            IndicatorSeparator: () => null,
                                                        }}
                                                    />
                                                    {projectError && <p className='small text-danger ps-2 '>Please select Project!</p>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6 d-flex justify-content-between">
                                                <div className="title d-flex align-items-center">
                                                    <span>Sort Order</span>
                                                </div>
                                                <div className="title d-flex align-items-center w-50">
                                                    <input
                                                        type='number'
                                                        className={`form-control mb-2`}
                                                        value={inputFavSortOrder > 0 && inputFavSortOrder}
                                                        onChange={(e) => setInputFavSortOrder(e.target.value > 0 && e.target.value)}
                                                        placeholder='Please enter... '
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6 d-flex justify-content-between">
                                                <div className="title d-flex ">
                                                </div>

                                                <div className='submit-footer d-flex justify-content-center '>
                                                    <div className=" submit text-end col-6 d-flex align-items-center ">
                                                        <button
                                                            onClick={handleSave}
                                                            className="submit-btn btn btn-primary btn-lg btn">
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* ======================================Favourite List================================== */}
                        <div className='mt-5'>
                            <table className='table tableCustom table-hover'>
                                <thead className='w-100'>
                                    <tr className='row'>
                                        <th className='col' scope='col'>Function</th>
                                        <th className='col' scope='col'>Service Category</th>
                                        <th className='col' scope='col'>Service Displine</th>
                                        <th className='col' scope='col'>Project</th>
                                        <th className='col' scope='col'>Sort Order</th>
                                        <th className='col text-end' scope='col'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody className='w-100'>
                                    {allUserFavourite?.length > 0 ? (

                                        allUserFavourite?.map((favourite, i) => <tr className='row' key={i}>


                                            <td className='col' >
                                                {
                                                    allFunctions?.length > 0 && allFunctions?.find((f) => f.Id === favourite?.FunctionId)?.Name
                                                }
                                            </td>
                                            <td className='col' >
                                                {
                                                    allServiceCategories?.length > 0 && allServiceCategories?.find((f) => f.Id === favourite?.ServiceCategoriesId)?.ServiceCategoriesName
                                                }
                                            </td>
                                            <td className='col' >
                                                {
                                                    allServiceDisciplines?.length > 0 && allServiceDisciplines?.find((f) => f.Id === favourite?.ServiceDisciplinesId)?.ServiceDisciplinesName
                                                }
                                            </td>
                                            <td className='col' >
                                                {
                                                    allProjects?.length > 0 && allProjects?.find((f) => f.Id === favourite?.ProjectId)?.ProjectName
                                                }
                                            </td>
                                            <td className="col">{favourite?.SortOrder}</td>
                                            <td className='col buttons' align='right'>
                                                <button
                                                    type='button'
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#editfavourite"
                                                    className='edit-btn btn btn-primary-w btn-icon me-2'
                                                    onClick={() => setFavouriteData(favourite)}
                                                >
                                                    <i className="fa-solid fa-pen p-2" />
                                                </button>
                                                <button
                                                    type='button'
                                                    className='delete-btn btn btn-primary-w btn-icon me-2'
                                                    onClick={() => handleDeleteFavourite(favourite?.Id)}
                                                >
                                                    <i className="fa-solid fa-trash-can p-2" />
                                                </button>
                                            </td>
                                        </tr>)


                                    )

                                        : <tr><td className='d-flex justify-content-center'>No Favourite Available</td></tr>
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>

                {/* ======================= Modal - Edit Favourite ================================================ */}
                <div className="modal fade" id="editfavourite" tabIndex={- 1}>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title'>Edit Favourite</h5>
                                <button type='button' className='btn-close' aria-label='Close' data-bs-dismiss="modal" />
                            </div>
                            <div className='modal-body mb-4'>
                                <form className='row g-3'>
                                    <div className='col-12 mb-3 mt-2'>
                                        <label htmlFor='inputName4' className='form-label'>Function</label>
                                        <Select
                                            value={allFunctions?.length > 0 && modalFavFunctionInput ? ({
                                                value: modalFavFunctionInput,
                                                label: allFunctions?.find((func) => func?.Id === modalFavFunctionInput)?.Name,
                                            }) : (
                                                { value: 0, label: "Select function" }
                                            )}
                                            onChange={(e) => {
                                                setModalFavFunctionInput(Number(e.value))
                                                setModalFavServiceCategoryInput(0)
                                                setModalFavServiceDiscplineInput(0)
                                            }}
                                            options={
                                                allFunctions?.length > 0
                                                    ? [
                                                        { value: 0, label: "Select function" },
                                                        ...allFunctions?.filter((d) => d?.IsActive)?.map((func) => ({
                                                            value: func?.Id,
                                                            label: func?.Name,
                                                        }))
                                                    ]
                                                    : []
                                            }
                                            className="form-control p-1"
                                            placeholder="Select function"
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    marginTop: 2,
                                                    border: "none",
                                                    boxShadow: "none",
                                                }),
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                        {functionModalError && <p className='small text-danger ps-2 '>Please select function!</p>}
                                    </div>
                                    <div className='col-12 mb-3 m-0'>
                                        <label htmlFor='inputName4' className='form-label'>Service categories</label>
                                        <Select
                                            value={allServiceCategories?.length > 0 && modalFavServiceCategoryInput && allServiceCategories?.find((cate) => cate?.Id === modalFavServiceCategoryInput)?.Id ? ({
                                                value: modalFavServiceCategoryInput,
                                                label: allServiceCategories?.find((cat) => cat?.Id === modalFavServiceCategoryInput)?.ServiceCategoriesName
                                            }) : (
                                                { value: 0, label: "Select service category" }
                                            )}
                                            onChange={(e) => {
                                                setModalFavServiceCategoryInput(Number(e.value))
                                                setModalFavServiceDiscplineInput(0)
                                            }}
                                            options={allServiceCategories?.length > 0 &&
                                                (modalFavFunctionInput
                                                    ? allServiceCategories?.filter((cate) => cate?.FunctionId === modalFavFunctionInput)
                                                    : allServiceCategories
                                                )?.filter((d) => d?.IsActive)?.map((cat) => ({
                                                    value: cat?.Id,
                                                    label: cat?.ServiceCategoriesName
                                                }))
                                            }
                                            className="form-control p-1"
                                            placeholder="Select service category"
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    marginTop: 2,
                                                    border: "none",
                                                    boxShadow: "none",
                                                }),
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                        {serviceCategoryModalError && <p className='small text-danger ps-2 '>Please select Service Category!</p>}
                                    </div>
                                    <div className='col-12 mb-3 m-0'>
                                        <label htmlFor='inputName4' className='form-label'>Service displines</label>
                                        <Select
                                            value={allServiceDisciplines?.length > 0 && modalFavServiceDiscplineInput && allServiceDisciplines?.find((discipline) => discipline?.Id === modalFavServiceDiscplineInput)?.Id ? ({
                                                value: modalFavServiceDiscplineInput,
                                                label: allServiceDisciplines?.find((discipline) => discipline?.Id === modalFavServiceDiscplineInput)?.ServiceDisciplinesName
                                            }) : (
                                                { value: 0, label: "Select service discipline" }
                                            )}
                                            onChange={(e) => setModalFavServiceDiscplineInput(Number(e.value))}
                                            options={allServiceDisciplines.length > 0 && (
                                                (modalFavServiceCategoryInput
                                                    ? allServiceDisciplines?.filter((discipline) => discipline?.ServiceCategoriesId === modalFavServiceCategoryInput)
                                                    : allServiceDisciplines
                                                )?.filter((d) => d?.IsActive)?.map((discipline) => (
                                                    ({ value: discipline?.Id, label: discipline?.ServiceDisciplinesName })
                                                ))
                                            )}
                                            className="form-control p-1"
                                            placeholder="Select service discipline"
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    marginTop: 2,
                                                    border: "none",
                                                    boxShadow: "none",
                                                }),
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                        {serviceDisciplineModalError && <p className='small text-danger ps-2 '>Please select Service Discpline!</p>}
                                    </div>
                                    <div className='col-12 mb-3 m-0'>
                                        <label htmlFor='inputName4' className='form-label'>Project</label>
                                        <Select
                                            value={allProjects?.length > 0 && modalFavProjectInput && allProjects?.find((pro) => pro?.Id === modalFavProjectInput)?.Id ? ({
                                                value: modalFavProjectInput,
                                                label: allProjects?.find((pro) => pro?.Id === modalFavProjectInput)?.ProjectName
                                            }) : (
                                                { value: 0, label: "Select project" }
                                            )}
                                            onChange={(e) => setModalFavProjectInput(Number(e.value))}
                                            options={allProjects?.length > 0 && [
                                                { value: 0, label: "Select project" },
                                                ...allProjects?.filter((d) => d?.IsActive)?.map((pro) => (
                                                    ({ value: pro?.Id, label: pro?.ProjectName })
                                                ))
                                            ]
                                            }
                                            className="form-control p-1"
                                            placeholder="Select"
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    marginTop: 2,
                                                    border: "none",
                                                    boxShadow: "none",
                                                }),
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                        {projectModalError && <p className='small text-danger ps-2 '>Please select Project!</p>}
                                    </div>
                                    <div className='col-12 mb-3 mt-2'>
                                        <label htmlFor='inputName4' className='form-label'>Sort Order</label>
                                        <input
                                            type='number'
                                            className='form-control '
                                            placeholder='Please enter...'
                                            value={modalFavSortOrder}
                                            onChange={(e) => setModalFavSortOrder(e.target.value > 0 && e.target.value)}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className='modal-footer'>
                                <button
                                    type='button'
                                    data-bs-dismiss="modal"
                                    className='cancel-btn btn btn-transparent btn-lg m-0 me-2'
                                    onClick={() => {
                                        setFunctionModalError(false)
                                        setServiceCategoryModalError(false)
                                        setServiceDisciplineModalError(false)
                                        setProjectModalError(false)
                                        setFaveriteId(0)
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    type='button'
                                    data-bs-dismiss={`${modalFavFunctionInput !== 0 && ((modalFavFunctionInput
                                        ? allServiceCategories?.filter((cate) => cate?.FunctionId === modalFavFunctionInput)
                                        : allServiceCategories
                                    )?.length > 0 ?
                                        modalFavServiceCategoryInput !== 0 : modalFavServiceCategoryInput === 0) && ((modalFavServiceCategoryInput
                                            ? allServiceDisciplines?.filter((discipline) => discipline?.ServiceCategoriesId === modalFavServiceCategoryInput)
                                            : allServiceDisciplines
                                        )?.length > 0 ?
                                            modalFavServiceDiscplineInput !== 0 : modalFavServiceDiscplineInput === 0) &&
                                        (allServiceCategories?.filter((cate) => cate?.Id === modalFavServiceCategoryInput)[0]?.ProjectSelection ? modalFavProjectInput !== 0 : true)
                                        ? "modal"
                                        : ""}`}
                                    className='submit-btn btn btn-primary btn-lg m-0'
                                    onClick={handleUpdate}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div >
            </main>
        </>
    )
}

export default Preference