import React from 'react'
import { Route, Routes as DomRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Header from './components/Header/Header'
import Sidebar from './components/Sidebar/Sidebar'
import Projects from './pages/Projects/Projects'
import Function from './pages/Function/Function'
import ServiceDiscipline from './pages/ServiceDiscipline/ServiceDiscipline'
import ServiceCategories from './pages/ServiceCategories/ServiceCategories'
import User from './pages/User/User'
import Error from './components/Error/Error'
import NewTimesheet from "./pages/NewTimesheet/NewTimesheet"
import Preference from './pages/Preference/Preference'
import Timesheet from './pages/Timesheet/Timesheet'
import ApprovalStatus from './pages/ApprovalStatus/ApprovalStatus'
import RedirectScreen from "./pages/RedirectScreen/RedirectScreen"
import { Navigate } from "react-router-dom"
import Holidays from './pages/Holidays/Holidays'
import Import from './pages/Import/Import'
import TimesheetSubmission from './pages/TimesheetSubmission/TimesheetSubmission'
import ImportHolidays from './pages/ImportHolidays/ImportHolidays'
import PreferredLocation from './pages/PreferredLocation/PreferredLocation'
import Dashboard from './pages/Dashboard/Dashboard'
import Notifications from './pages/Notifications/Notifications'
import Periods from './pages/Periods/Periods'

export default function Routes() {
  const currentUser = useSelector((state) => state.MSALAuth.currentUser)

  return (
    <>
      {currentUser?.Data?.IsActive ? (
        <>
          <Header />
          {currentUser?.Data?.IsAdmin && <Sidebar />}
          <DomRouter>
            {/* <Route index element={<NewTimesheet />} /> */}
            <Route path="/" element={<Navigate to="/timesheet" />} />
            <Route path="/timesheet" element={<RedirectScreen />} />
            <Route path="/favourite" element={<Preference />} />
            <Route path="/preferred-location" element={<PreferredLocation />} />
            <Route path="/report" element={<Dashboard />} />
            {
              currentUser?.Data?.IsAdmin &&
              (
                <>
                  <Route path="/projects" element={<Projects />} />
                  <Route path="/function" element={<Function />} />
                  <Route path="/service-discipline" element={<ServiceDiscipline />} />
                  <Route path="/service-categories" element={<ServiceCategories />} />
                  <Route path="/users" element={<User />} />
                  <Route path="/approval" element={<ApprovalStatus />} />
                  <Route path="/import-timesheet" element={<Import />} />
                  <Route path="/holidays" element={<Holidays />} />
                  <Route path="/import-holidays" element={<ImportHolidays />} />
                  <Route path="/notifications" element={<Notifications />} />
                  <Route path="/period" element={<Periods />} />
                </>
              )}
            <Route path="*" element={<Error Status={404} Message={"The page you are looking for doesn't exist."} />} />
          </DomRouter>
        </>
      ) : (
        <Error Status={400} Message={"Bad Request. User is inactive, please contact admin."} />
      )}
    </>
  )
}
